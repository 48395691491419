import { LandingPageComponent } from './component/landing-page/landing-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { AuthComponent } from './auth/auth.component'
import { AuthGuard } from '../app/services/auth.guard'
import { NotauthComponent } from '../app/notauth/notauth.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
const routes: Routes = [
   {
    path: '',
    children: [
      {
        path: '',
        component: LandingPageComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: null, canActivate: [AuthComponent], component: LandingPageComponent,
        },
      },
      {
        path: 'home',
        loadChildren: () => import('../app/component/landing-page/landing-page.module').then(x => x.LandingPageModule)
      },
      {
        path: 'admin',
        loadChildren: () => import('../app/component/ci-custom-query/ci-custom-query/ci-custom-query.module').then(x => x.CiCustomQueryModule),
      },
      {
        path: 'search',
        loadChildren: () => import('../app/component/ci/ci.module').then(x => x.CiModule)
      },
      {
        path: 'NoAuth',
        component: NotauthComponent
      }
    ]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }