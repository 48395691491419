import { LandingPageModule } from './../app/component/landing-page/landing-page.module';
import { CiModule } from './component/ci/ci.module';
import { CiCustomQueryModule } from './component/ci-custom-query/ci-custom-query/ci-custom-query.module';
import { BrowserModule } from '@angular/platform-browser';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { SelectAutocompleteModule } from 'mat-select-autocomplete';
import { DragDropModule } from '@angular/cdk/drag-drop';

import {
  MatToolbarModule,
  MatIconModule,
  MatSidenavModule,
  MatListModule,
  MatButtonModule,
  MatSelectModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatTableModule,
  MatDialogModule,
  MatMenuModule,
  MatTabsModule,
  MatRadioModule


} from "@angular/material";
import { MatCardModule } from "@angular/material/card";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatPaginatorModule } from "@angular/material/paginator";
import { AgGridModule } from "../../../node_modules/ag-grid-angular";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { LayoutModule } from "@angular/cdk/layout";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { ConfigitemsComponent } from './configitems/configitems.component';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { LandingPageComponent } from './component/landing-page/landing-page.component';
import { SideNavComponent } from './component/side-nav/side-nav.component';
import { MainNavComponent } from './component/main-nav/main-nav.component';
import { InfoCardComponent } from './component/shared/info-card/info-card.component';
import { CiCustomQueryComponent } from './component/ci-custom-query/ci-custom-query.component';
import { ShowColumnsDialogComponent } from './component/show-columns-dialog/show-columns-dialog.component';
import { ProgressbarDialogComponent } from './component/progressbar-dialog/progressbar-dialog.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { CookieService } from 'ngx-cookie-service';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { BreadcrumbComponent } from '../app/bread-crumb/bread-crumb.component';
import { NotauthComponent } from './notauth/notauth.component';


//Auth.configure(AWSConfig);

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    SideNavComponent,
    MainNavComponent,
    InfoCardComponent,
    ConfigitemsComponent,
    ShowColumnsDialogComponent,
    ProgressbarDialogComponent,
    BreadcrumbComponent,
    NotauthComponent

  ],
  entryComponents: [
    ShowColumnsDialogComponent,
    ProgressbarDialogComponent
  ],
  imports: [
    CiModule,
    LandingPageModule,
    CiCustomQueryModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AmplifyAngularModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatCardModule,
    MatGridListModule,
    MatPaginatorModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    MatMenuModule,
    MatTabsModule,
    MatRadioModule,
    SelectAutocompleteModule,
    NgIdleKeepaliveModule.forRoot(),
    HttpClientModule,
    AgGridModule.withComponents([]),
    BreadcrumbModule,
    MenubarModule,
    MenuModule,
    DragDropModule
  ],
  providers: [AmplifyService],
  bootstrap: [AppComponent]
})
export class AppModule { }
