import { Injectable, ɵConsole } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Auth } from "aws-amplify";
import { AuthService } from "../auth.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return Auth.currentAuthenticatedUser({ bypassCache: true })
      .then((user) => {
        localStorage.setItem("isloggedIn", "true");
        this.authService.user = user;
        this.authService.loggedIn = true;
        return true;
      })
      .catch(() => {
        localStorage.removeItem("isloggedIn");
        this.authService.loggedIn = false;
        return false;
      });
  }
}
