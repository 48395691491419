import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api/';
import { isNullOrUndefined } from 'util';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent, RoutesRecognized } from '@angular/router';
import { filter } from 'rxjs/operators';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/pairwise';
import { Location } from '@angular/common';
import { Boolean } from 'aws-sdk/clients/ecs';
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss']
})


export class BreadcrumbComponent implements OnInit {
  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  readonly home = { icon: 'pi pi-home', url: '#/home' };
  menuItems: MenuItem[];
  previousurl: any;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private location: Location) { }

  ngOnInit(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => this.menuItems = this.createBreadcrumbs(this.activatedRoute.root));
    console.log("Previous URL" + this.activatedRoute.fragment)

    this.router.events
      .filter(e => e instanceof RoutesRecognized)
      .pairwise()
      .subscribe((event: any[]) => {
        this.previousurl = event[0].urlAfterRedirects;
      });
  }
  private titleCase(input: string) {
    let sentence = input.toLowerCase().split(" ");
    for (let i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }

    return sentence.join(" ");
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '#', breadcrumbs: MenuItem[] = []): MenuItem[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0)
      return breadcrumbs;


    let currentLable: string = ""
    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      //console.log('routeURL ' + routeURL);
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }
      const label = child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_BREADCRUMB];
      //console.log("Previous URL " + this.location.back());
      if (!isNullOrUndefined(this.previousurl)) {
        if (label == "Search" && this.previousurl.includes("search") && this.previousurl.length > 10)
          url += this.previousurl;
      }
      currentLable == label;
      let found = breadcrumbs.find(x => x.label == currentLable)
      //console.log("Found ", breadcrumbs.find(x => x.label === label));
      if (!isNullOrUndefined(found) || breadcrumbs.length == 0) {
        //console.log('URL ' + url + '  Label ' + label);
        if (!isNullOrUndefined(label) && url != '#') {
          url = url.toLocaleLowerCase();
          breadcrumbs.push({ label, url });
        }
      }
      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

}
