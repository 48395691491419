import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

export interface DialogData {
  AvailableColumns: any[];
  SelectedColumns: any[];
}

@Component({
  selector: 'app-show-columns-dialog',
  templateUrl: './show-columns-dialog.component.html',
  styleUrls: ['./show-columns-dialog.component.scss']
})
export class ShowColumnsDialogComponent {
  localDta: DialogData;
  removedItem: {};
  constructor(
    public dialogRef: MatDialogRef<ShowColumnsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.removedItem = data.SelectedColumns.splice(data.SelectedColumns.length - 1, 1);
    //console.log(this.removedItem[0]);
    this.localDta = data;
    //console.log(this.localDta.AvailableColumns)
    //console.log(this.localDta.SelectedColumns)
  }

  onNoClick(): void {

    //console.log(this.removedItem[0])
    //console.log(this.localDta.SelectedColumns);
    this.localDta.SelectedColumns.push(this.removedItem[0]);
    this.dialogRef.close();
  }

  AddToSelectedItemsList(item, index) {
    //console.log("inside AddToSelectedItemsList")
    //console.log(this.localDta.AvailableColumns);
    this.localDta.AvailableColumns.splice(index, 1)
    this.localDta.SelectedColumns.push(item)
  }

  RemoveFromSelectedItemsList(item, index) {
    //console.log("inside RemoveFromSelectedItemsList")
    //console.log(this.localDta.SelectedColumns)
    this.localDta.SelectedColumns.splice(index, 1)
    this.localDta.AvailableColumns.push(item);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.localDta.SelectedColumns, event.previousIndex, event.currentIndex);
  }
}
