import { LandingPageRoutingModule } from '../landing-page/landing-page-routing.module';
import { LandingPageComponent } from '../landing-page/landing-page.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectAutocompleteModule } from 'mat-select-autocomplete';
import {
  MatToolbarModule,
  MatIconModule,
  MatSidenavModule,
  MatListModule,
  MatButtonModule,
  MatSelectModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatTableModule,
  MatDialogModule,
  MatMenuModule,
  MatTabsModule,
  MatRadioModule
} from "@angular/material";
import { MatCardModule } from "@angular/material/card";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatPaginatorModule } from "@angular/material/paginator";
import { AgGridModule } from "../../../../../node_modules/ag-grid-angular"
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { LayoutModule } from "@angular/cdk/layout";

@NgModule({
  declarations: [LandingPageComponent],
  imports: [
    CommonModule,
    LandingPageRoutingModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatDialogModule,
    MatMenuModule,
    MatTabsModule,
    MatRadioModule,
    MatCardModule,
    MatGridListModule,
    MatPaginatorModule,
    AgGridModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    SelectAutocompleteModule,
  ]
})
export class LandingPageModule { }
