import { Injectable, NgZone } from '@angular/core';
import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Hub, ICredentials } from '@aws-amplify/core';
import { Subject, Observable, from } from 'rxjs';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AmplifyService } from 'aws-amplify-angular';
import { Router } from '@angular/router';
import { resolve } from 'url';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  public loggedIn: boolean = false;
  private _authState: Subject<CognitoUser | any> = new Subject<CognitoUser | any>();
  authState: Observable<CognitoUser | any> = this._authState.asObservable();
  public user

  constructor(public amplifyService: AmplifyService, public router: Router, private ngZone: NgZone) {
    //This event will get called , only after user has completed signed in process from global pass
    Hub.listen('auth', (data) => {
      const { channel, payload } = data;
      if (channel === 'auth') {
        this._authState.next(payload.event);
      }
    });
    this.amplifyService.authStateChange$
      .subscribe(authState => {

        // this.loggedIn = authState.state === 'signedIn';
        if (!authState.user) {
          this.user = null;
        } else {
          this.loggedIn = true
          this.user = authState.user;

        }
      });
  }

  getUserName() {
    if (this.user != null) {
      //console.log(this.user)
      let name = this.user.attributes.given_name;
      return name;
    } else {
      return "";
    }
  }

  getUserMail() {
    if (this.user != null) {
      return this.user.attributes.email;
    } else {
      return "";
    }
  }

  getAltCompanyCode() {
    if (this.user != null) {
      return this.user.attributes.profile;
    } else {
      return "";
    }
  }

  getUserLastName() {
    if (this.user != null) {
      return this.user.attributes.family_name;
    } else {
      return "";
    }
  }

  async signIn(customProvider: 'GlobalPass') {
    return new Promise((resolve, reject) => {
      Auth.federatedSignIn({ customProvider: 'GlobalPass' })
        .then((user: CognitoUser | any) => {
          this.loggedIn = true;
          resolve(user);
        }).catch((error: any) => reject(error));
    })
  }
}