import { Component, OnInit } from "@angular/core";
import { Logger } from "aws-amplify";
import { AmplifyAPIServiceService } from "../services/amplify-apiservice.service";
import { ProgressbarService } from "../services/progressbar.service";
@Component({
  selector: "app-notauth",
  templateUrl: "./notauth.component.html",
  styleUrls: ["./notauth.component.scss"],
})
export class NotauthComponent implements OnInit {
  Webtext: string = "";
  constructor(
    private API: AmplifyAPIServiceService,
    private progress: ProgressbarService
  ) {}

  async ngOnInit(): Promise<any> {
    let progress = this.progress.start();
    Promise.all([this.getWebText()]).then((result) => {
      this.Webtext = result[0];
    });
    this.progress.stop(progress);
  }

  async getWebText(): Promise<string> {
    //let info = await this.API.ListAnaIntelliSearchContents({ key: { eq: "NotAuthorized" } })
    let info = "Testing";
    try {
      // if (info != null && info.items != null && info.items[0] != null) {
      //   return info.items[0].value
      // }
      return "Testing";
    } catch (e) {}
  }
}
