import { EventEmitter, Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ProgressbarDialogComponent } from './../component/progressbar-dialog/progressbar-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ProgressbarService {

  constructor(private router: Router, private dialog: MatDialog) { }
  start(message?): MatDialogRef<ProgressbarDialogComponent> {

    const dialogRef = this.dialog.open(ProgressbarDialogComponent, {
      disableClose: true,
      data: message == '' || message == undefined ? "Loading..." : message
    });
    return dialogRef;
  };

  stop(ref: MatDialogRef<ProgressbarDialogComponent>) {
    ref.close();
  }
}
