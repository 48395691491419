// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "ap-southeast-2",
  "aws_cognito_identity_pool_id": "ap-southeast-2:dcb458ae-9959-4db8-86e9-d3cbe8616d0e",
  "aws_cognito_region": "ap-southeast-2",
  //"aws_user_pools_id": "us-east-1_qhDK6Wqby",
  //"aws_user_pools_web_client_id": "6vi4uqc6v6qjcp3n37msji3tpm",
  "aws_user_pools_id": "ap-southeast-2_npvah70l6",
  "aws_user_pools_web_client_id": "6ha30bmvd7lmm7pbtmcqigj45l",
  "oauth": {
    "domain": "dxc-au00-prd0.auth.ap-southeast-2.amazoncognito.com",
    "scope": [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    "redirectSignIn": "https://intelli-search.au.platform.dxc.com",
    "redirectSignOut": "https://intelli-search.au.platform.dxc.com",
    // "redirectSignIn": "http://localhost:4200/",
    // "redirectSignOut": "http://localhost:4200/",
    "responseType": "code"
  },
  "federationTarget": "COGNITO_USER_POOLS",
  "aws_appsync_graphqlEndpoint": "https://fc35yqtgwzhejpgvh55sdzlv4a.appsync-api.ap-southeast-2.amazonaws.com/graphql",
  "aws_appsync_region": "ap-southeast-2",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};
export default awsmobile;