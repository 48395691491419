import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AmplifyAPIServiceService } from "./../../services/amplify-apiservice.service"
import { AuthService } from '../../auth.service';
import { ProgressbarService } from '../../services/progressbar.service';
import { NotauthComponent } from '../../../app/notauth/notauth.component';
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LandingPageComponent implements OnInit {

  contactInfo = [];
  releaseNotes = "";
  userDoc = [];
  companyName: string = "";
  Matching: boolean = true
  constructor(private router: Router, private api: AmplifyAPIServiceService, private auth: AuthService, private progress: ProgressbarService) {
  }

  async ngOnInit(): Promise<any> {
    let progressbar = this.progress.start();
    Promise.all([
      this.getContactInfo(),
      this.getReleaseNotes(),
      this.getUserDocuments(),
      this.isDXCUser()
    ]).then(result => {
      this.contactInfo = result[0];
      this.releaseNotes = result[1];
      this.userDoc = result[2];
      this.Matching = result[3];
    })
    this.progress.stop(progressbar);
  }

  async getContactInfo(): Promise<string[]> {
    let info = await this.api.ListAnaIntelliSearchContents({ key: { eq: "contactInfo" } })
    try {
      if (info != null && info.items != null && info.items[0] != null) {
        return JSON.parse(info.items[0].value)
      }
    } catch (e) {
      this.contactInfo = []
    }
  }

  async isDXCUser(): Promise<boolean> {
    let info = await this.api.ListAnaIntelliSearchContents({ key: { eq: "CompanyName" } })
    try {
      if (info != null && info.items != null && info.items[0] != null) {
        if (info.items[0].value.toUpperCase() === this.auth.getAltCompanyCode().toUpperCase()) {
          return true
        }
        else {
          this.router.navigate(['NoAuth'], { queryParams: {}, replaceUrl: true });
          return false
        }
      }
    } catch (e) {
      return false

    }
  }

  async getReleaseNotes(): Promise<string> {
    let info = await this.api.ListAnaIntelliSearchContents({ key: { eq: "releaseNotes" } })
    try {
      if (info != null && info.items != null && info.items[0] != null) {
        return info.items[0].value
      }
    } catch (e) {
      return ""
    }
  }

  async getUserDocuments(): Promise<string[]> {
    let info = await this.api.ListAnaIntelliSearchContents({ key: { eq: "userDoc" } })
    try {
      if (info != null && info.items != null && info.items[0] != null) {
        return JSON.parse(info.items[0].value)
      }
    } catch (e) {
      return []
    }
  }

  verServer(idx: number) {
    this.router.navigate(['/heroe', idx]);
  }

}
