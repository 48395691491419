/* tslint:disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import * as Observable from "zen-observable";

export type CreateAnaIntelliSearchContentInput = {
  key: string;
  value: string;
};

export type ModelanaIntelliSearchContentConditionInput = {
  key?: ModelIDInput | null;
  value?: ModelStringInput | null;
  and?: Array<ModelanaIntelliSearchContentConditionInput | null> | null;
  or?: Array<ModelanaIntelliSearchContentConditionInput | null> | null;
  not?: ModelanaIntelliSearchContentConditionInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type UpdateAnaIntelliSearchContentInput = {
  key?: string | null;
  value?: string | null;
};

export type DeleteAnaIntelliSearchContentInput = {
  id?: string | null;
};

export type CreateAnaSourceAppCodeInput = {
  src_app_cd?: string | null;
};

export type ModelanaSourceAppCodeConditionInput = {
  src_app_cd?: ModelStringInput | null;
  and?: Array<ModelanaSourceAppCodeConditionInput | null> | null;
  or?: Array<ModelanaSourceAppCodeConditionInput | null> | null;
  not?: ModelanaSourceAppCodeConditionInput | null;
};

export type UpdateAnaSourceAppCodeInput = {
  src_app_cd?: string | null;
};

export type DeleteAnaSourceAppCodeInput = {
  id?: string | null;
};

export type CreateAnaClientCodeModelInput = {
  clnt_cd?: string | null;
};

export type ModelanaClientCodeModelConditionInput = {
  clnt_cd?: ModelStringInput | null;
  and?: Array<ModelanaClientCodeModelConditionInput | null> | null;
  or?: Array<ModelanaClientCodeModelConditionInput | null> | null;
  not?: ModelanaClientCodeModelConditionInput | null;
};

export type UpdateAnaClientCodeModelInput = {
  clnt_cd?: string | null;
};

export type DeleteAnaClientCodeModelInput = {
  id?: string | null;
};

export type CreateAnaCloudStackModelInput = {
  cloud_stack_nm?: string | null;
};

export type ModelanaCloudStackModelConditionInput = {
  cloud_stack_nm?: ModelStringInput | null;
  and?: Array<ModelanaCloudStackModelConditionInput | null> | null;
  or?: Array<ModelanaCloudStackModelConditionInput | null> | null;
  not?: ModelanaCloudStackModelConditionInput | null;
};

export type UpdateAnaCloudStackModelInput = {
  cloud_stack_nm?: string | null;
};

export type DeleteAnaCloudStackModelInput = {
  id?: string | null;
};

export type ModelCIFilterInput = {
  asset_tag_nm?: ModelCIStringInput | null;
  asgne_cntct_nm?: ModelCIStringInput | null;
  asset_sk?: ModelCIStringInput | null;
  can_prt_fg?: ModelCIBooleanInput | null;
  ci_nm?: ModelCIStringInput | null;
  cls_nm?: ModelCIStringInput | null;
  co_ky?: ModelCIStringInput | null;
  fault_ct?: ModelCIIntInput | null;
  instl_stat_nm?: ModelCIStringInput | null;
  u_use_for_nm?: ModelCIStringInput | null;
  loc_nm?: ModelCIStringInput | null;
  oprtnl_catg_tier_1_nm?: ModelCIStringInput | null;
  oprtnl_catg_tier_2_nm?: ModelCIStringInput | null;
  owned_by_cntct_nm?: ModelCIStringInput | null;
  suprt_by_cntct_nm?: ModelCIStringInput | null;
  suprt_wkgp_nm?: ModelCIStringInput | null;
  lgcl_del_fg?: ModelCIBooleanInput | null;
  mntr_fg?: ModelCIBooleanInput | null;
  ci_ky?: ModelCIStringInput | null;
  corltn_id?: ModelCIStringInput | null;
  sht_dn_tx?: ModelCIStringInput | null;
  dept_nm?: ModelCIStringInput | null;
  upd_by_cntct_nm?: ModelCIStringInput | null;
  bus_crtclty_nm?: ModelCIStringInput | null;
  bus_srvc_clsfc_nm?: ModelCIStringInput | null;
  u_suprt_nm?: ModelCIStringInput | null;
  and?: Array<ModelCIFilterInput | null> | null;
  or?: Array<ModelCIFilterInput | null> | null;
};

export type ModelCIStringInput = {
  eq?: string | null;
  ne?: string | null;
  beginsWith?: string | null;
  endsWith?: string | null;
  contains?: string | null;
};

export type ModelCIBooleanInput = {
  eq?: boolean | null;
};

export type ModelCIIntInput = {
  eq?: number | null;
  ne?: number | null;
};

export type ModelCIRelationFilterInput = {
  child_ci_ky?: ModelCIStringInput | null;
  ci_ky?: ModelCIStringInput | null;
  parnt_ci_ky?: ModelCIStringInput | null;
  lgcl_del_fg?: ModelCIBooleanInput | null;
  instl_stat_nm?: ModelCIStringInput | null;
  ci_nm?: ModelCIStringInput | null;
  co_nm?: ModelCIStringInput | null;
  co_ky?: ModelCIStringInput | null;
  and?: Array<ModelCIRelationFilterInput | null> | null;
  or?: Array<ModelCIRelationFilterInput | null> | null;
};

export type ModelanaIntelliSearchContentFilterInput = {
  key?: ModelIDInput | null;
  value?: ModelStringInput | null;
  and?: Array<ModelanaIntelliSearchContentFilterInput | null> | null;
  or?: Array<ModelanaIntelliSearchContentFilterInput | null> | null;
  not?: ModelanaIntelliSearchContentFilterInput | null;
};

export type ModelanaSourceAppCodeFilterInput = {
  src_app_cd?: ModelStringInput | null;
  and?: Array<ModelanaSourceAppCodeFilterInput | null> | null;
  or?: Array<ModelanaSourceAppCodeFilterInput | null> | null;
  not?: ModelanaSourceAppCodeFilterInput | null;
};

export type ModelanaClientCodeModelFilterInput = {
  clnt_cd?: ModelStringInput | null;
  and?: Array<ModelanaClientCodeModelFilterInput | null> | null;
  or?: Array<ModelanaClientCodeModelFilterInput | null> | null;
  not?: ModelanaClientCodeModelFilterInput | null;
};

export type ModelanaCloudStackModelFilterInput = {
  cloud_stack_nm?: ModelStringInput | null;
  and?: Array<ModelanaCloudStackModelFilterInput | null> | null;
  or?: Array<ModelanaCloudStackModelFilterInput | null> | null;
  not?: ModelanaCloudStackModelFilterInput | null;
};

export type CreateAnaIntelliSearchContentMutation = {
  __typename: "anaIntelliSearchContent";
  key: string;
  value: string;
};

export type UpdateAnaIntelliSearchContentMutation = {
  __typename: "anaIntelliSearchContent";
  key: string;
  value: string;
};

export type DeleteAnaIntelliSearchContentMutation = {
  __typename: "anaIntelliSearchContent";
  key: string;
  value: string;
};

export type CreateAnaSourceAppCodeMutation = {
  __typename: "anaSourceAppCode";
  src_app_cd: string | null;
};

export type UpdateAnaSourceAppCodeMutation = {
  __typename: "anaSourceAppCode";
  src_app_cd: string | null;
};

export type DeleteAnaSourceAppCodeMutation = {
  __typename: "anaSourceAppCode";
  src_app_cd: string | null;
};

export type CreateAnaClientCodeModelMutation = {
  __typename: "anaClientCodeModel";
  clnt_cd: string | null;
};

export type UpdateAnaClientCodeModelMutation = {
  __typename: "anaClientCodeModel";
  clnt_cd: string | null;
};

export type DeleteAnaClientCodeModelMutation = {
  __typename: "anaClientCodeModel";
  clnt_cd: string | null;
};

export type CreateAnaCloudStackModelMutation = {
  __typename: "anaCloudStackModel";
  cloud_stack_nm: string | null;
};

export type UpdateAnaCloudStackModelMutation = {
  __typename: "anaCloudStackModel";
  cloud_stack_nm: string | null;
};

export type DeleteAnaCloudStackModelMutation = {
  __typename: "anaCloudStackModel";
  cloud_stack_nm: string | null;
};

export type ListPgCIsQuery = {
  __typename: "anaCI";
  asset_sk: string | null;
  asset_tag_nm: string | null;
  asgn_utc_ts: string | null;
  asgne_cntct_nm: string | null;
  asgne_cntct_sk: string | null;
  can_prt_fg: boolean | null;
  chg_appv_wkgp_nm: string | null;
  chg_appv_wkgp_sk: string | null;
  ci_ky: string;
  ci_nm: string | null;
  ci_sk: string;
  cls_nm: string | null;
  cmnt_tx: string | null;
  co_ky: string;
  co_nm: string | null;
  co_sk: string | null;
  corltn_id: string | null;
  cst_ctr_org_nm: string | null;
  cst_ctr_org_sk: string | null;
  data_dmn_nm: string | null;
  data_dmn_sk: string | null;
  dept_nm: string | null;
  dept_sk: string | null;
  dlvr_dt_utc_ts: string | null;
  dns_dmn_nm: string | null;
  dscvr_src_nm: string | null;
  fault_ct: number | null;
  fqdn_nm: string | null;
  frst_dscvr_utc_ts: string | null;
  gl_acct_nm: string | null;
  instl_stat_nm: string | null;
  instl_utc_ts: string | null;
  inv_nr_nm: string | null;
  ip_addr_nm: string | null;
  last_dscvr_utc_ts: string | null;
  loc_nm: string | null;
  loc_sk: string | null;
  mac_addr_nm: string | null;
  maint_sch_nm: string | null;
  maint_sch_sk: string | null;
  mdl_id: string | null;
  mdl_nr: string | null;
  mdl_sk: string | null;
  mfr_nm: string | null;
  mng_dmn_fg: boolean | null;
  mntr_fg: boolean | null;
  open_by_agnt_cntct_nm: string | null;
  oprtnl_catg_tier_1_nm: string | null;
  oprtnl_catg_tier_2_nm: string | null;
  oprtnl_catg_tier_3_nm: string | null;
  oprtnl_stat_nm: string | null;
  owned_by_cntct_nm: string | null;
  owned_by_cntct_sk: string | null;
  purch_ord_nr: string | null;
  purch_utc_ts: string | null;
  sch_nm: string | null;
  sch_sk: string | null;
  src_crt_utc_ts: string | null;
  src_upd_utc_ts: string | null;
  sht_dn_tx: string | null;
  splr_nm: string | null;
  splr_sk: string | null;
  srl_nr_nm: string | null;
  strt_utc_ts: string | null;
  suprt_by_cntct_nm: string | null;
  suprt_by_cntct_sk: string | null;
  suprt_wkgp_nm: string | null;
  suprt_wkgrp_sk: string | null;
  u_flr_nm: string | null;
  u_host_nm: string | null;
  u_mtch_type_nm: string | null;
  u_mntr_nm: string | null;
  u_no_scan_rsn_nm: string | null;
  u_rm_nm: string | null;
  u_seat_nm: string | null;
  u_shrd_fg: boolean | null;
  u_suprt_nm: string | null;
  u_use_for_nm: string | null;
  upd_by_cntct_nm: string | null;
  warr_exp_utc_ts: string | null;
  lgcl_del_fg: boolean | null;
  src_sys_ky: string | null;
  src_sys_nm: string | null;
  d1_crt_utc_ts: string | null;
  d2_chksum_tx: string | null;
  d2_crt_job_id: string | null;
  d2_crt_utc_ts: string | null;
  d2_load_cd: string | null;
  src_clnt_cd: string | null;
  asset_nm: string | null;
  avail_tgt_pc: string | null;
  lvl_of_svc_nm: string | null;
  bus_srvc_ci_nm: string | null;
  bus_srvc_clsfc_nm: string | null;
  bus_crtclty_nm: string | null;
  suprt_usr_wkgp_nm: string | null;
  mng_by_cntct_nm: string | null;
  crt_by_cntct_nm: string | null;
  attr_tx: string | null;
};

export type LovPgCIsQuery = {
  __typename: "lovCI";
  cls_nm: string | null;
  instl_stat_nm: string | null;
  u_use_for_nm: string | null;
};

export type ListSourceAppCodeQuery = {
  __typename: "anaSrcAppCode";
  src_app_cd: string | null;
};

export type ListClientCodeQuery = {
  __typename: "anaClientCodeType";
  clnt_cd: string | null;
};

export type ListCloudStackQuery = {
  __typename: "anaCloudStackType";
  cloud_stack_nm: string | null;
};

export type ListPgCiUpstreamQuery = {
  __typename: "anaCIRelationShip";
  parnt_ci_nm: string | null;
  ci_relshp_type_nm: string | null;
  child_ci_nm: string | null;
  parnt_ci_ky: string | null;
  cls_nm: string | null;
  instl_stat_nm: string | null;
  u_use_for_nm: string | null;
  corltn_id: string | null;
  co_sk: string | null;
  co_nm: string | null;
  child_ci_ky: string | null;
  lgcl_del_fg: boolean | null;
  ci_nm: string | null;
};

export type ListPgCiDownstreamQuery = {
  __typename: "anaCIRelationShip";
  parnt_ci_nm: string | null;
  ci_relshp_type_nm: string | null;
  child_ci_nm: string | null;
  parnt_ci_ky: string | null;
  cls_nm: string | null;
  instl_stat_nm: string | null;
  u_use_for_nm: string | null;
  corltn_id: string | null;
  co_sk: string | null;
  co_nm: string | null;
  child_ci_ky: string | null;
  lgcl_del_fg: boolean | null;
  ci_nm: string | null;
};

export type ListPgCiDetailsQuery = {
  __typename: "anaCIdetails";
  ci_nm: string | null;
  instl_stat_nm: string | null;
  u_use_for_nm: string | null;
  cls_nm: string | null;
  co_nm: string | null;
  co_ky: string | null;
  loc_nm: string | null;
  suprt_by_cntct_nm: string | null;
  asgne_cntct_nm: string | null;
  suprt_wkgp_nm: string | null;
  corltn_id: string | null;
  asset_tag_nm: string | null;
  mntr_fg: boolean | null;
  u_suprt_nm: string | null;
  owned_by_cntct_nm: string | null;
  src_crt_utc_ts: string | null;
  sht_dn_tx: string | null;
  upd_by_cntct_nm: string | null;
  src_upd_utc_ts: string | null;
  cmnt_tx: string | null;
  dept_nm: string | null;
  lgcl_del_fg: boolean | null;
  parnt_ci_nm: string | null;
  ci_relshp_type_nm: string | null;
  child_ci_nm: string | null;
  parnt_ci_ky: string | null;
  child_ci_ky: string | null;
  bus_srvc_ci_nm: string | null;
  bus_srvc_clsfc_nm: string | null;
  bus_crtclty_nm: string | null;
  suprt_usr_wkgp_nm: string | null;
  mng_by_cntct_nm: string | null;
  crt_by_cntct_nm: string | null;
  attr_tx: string | null;
  it_owner_org: string | null;
  it_support_org: string | null;
  bus_owner_org: string | null;
};

export type ListPgCiOrgsQuery = {
  __typename: "anaCIOrg";
  it_owner_org: string | null;
  it_support_org: string | null;
  bus_owner_org: string | null;
};

export type GetAnaIntelliSearchContentQuery = {
  __typename: "anaIntelliSearchContent";
  key: string;
  value: string;
};

export type ListAnaIntelliSearchContentsQuery = {
  __typename: "ModelanaIntelliSearchContentConnection";
  items: Array<{
    __typename: "anaIntelliSearchContent";
    key: string;
    value: string;
  } | null> | null;
  nextToken: string | null;
};

export type GetAnaSourceAppCodeQuery = {
  __typename: "anaSourceAppCode";
  src_app_cd: string | null;
};

export type ListAnaSourceAppCodesQuery = {
  __typename: "ModelanaSourceAppCodeConnection";
  items: Array<{
    __typename: "anaSourceAppCode";
    src_app_cd: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetAnaClientCodeModelQuery = {
  __typename: "anaClientCodeModel";
  clnt_cd: string | null;
};

export type ListAnaClientCodeModelsQuery = {
  __typename: "ModelanaClientCodeModelConnection";
  items: Array<{
    __typename: "anaClientCodeModel";
    clnt_cd: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetAnaCloudStackModelQuery = {
  __typename: "anaCloudStackModel";
  cloud_stack_nm: string | null;
};

export type ListAnaCloudStackModelsQuery = {
  __typename: "ModelanaCloudStackModelConnection";
  items: Array<{
    __typename: "anaCloudStackModel";
    cloud_stack_nm: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type OnCreateAnaIntelliSearchContentSubscription = {
  __typename: "anaIntelliSearchContent";
  key: string;
  value: string;
};

export type OnUpdateAnaIntelliSearchContentSubscription = {
  __typename: "anaIntelliSearchContent";
  key: string;
  value: string;
};

export type OnDeleteAnaIntelliSearchContentSubscription = {
  __typename: "anaIntelliSearchContent";
  key: string;
  value: string;
};

export type OnCreateAnaSourceAppCodeSubscription = {
  __typename: "anaSourceAppCode";
  src_app_cd: string | null;
};

export type OnUpdateAnaSourceAppCodeSubscription = {
  __typename: "anaSourceAppCode";
  src_app_cd: string | null;
};

export type OnDeleteAnaSourceAppCodeSubscription = {
  __typename: "anaSourceAppCode";
  src_app_cd: string | null;
};

export type OnCreateAnaClientCodeModelSubscription = {
  __typename: "anaClientCodeModel";
  clnt_cd: string | null;
};

export type OnUpdateAnaClientCodeModelSubscription = {
  __typename: "anaClientCodeModel";
  clnt_cd: string | null;
};

export type OnDeleteAnaClientCodeModelSubscription = {
  __typename: "anaClientCodeModel";
  clnt_cd: string | null;
};

export type OnCreateAnaCloudStackModelSubscription = {
  __typename: "anaCloudStackModel";
  cloud_stack_nm: string | null;
};

export type OnUpdateAnaCloudStackModelSubscription = {
  __typename: "anaCloudStackModel";
  cloud_stack_nm: string | null;
};

export type OnDeleteAnaCloudStackModelSubscription = {
  __typename: "anaCloudStackModel";
  cloud_stack_nm: string | null;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateAnaIntelliSearchContent(
    input: CreateAnaIntelliSearchContentInput,
    condition?: ModelanaIntelliSearchContentConditionInput
  ): Promise<CreateAnaIntelliSearchContentMutation> {
    const statement = `mutation CreateAnaIntelliSearchContent($input: CreateAnaIntelliSearchContentInput!, $condition: ModelanaIntelliSearchContentConditionInput) {
        createAnaIntelliSearchContent(input: $input, condition: $condition) {
          __typename
          key
          value
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAnaIntelliSearchContentMutation>(
      response.data.createAnaIntelliSearchContent
    );
  }
  async UpdateAnaIntelliSearchContent(
    input: UpdateAnaIntelliSearchContentInput,
    condition?: ModelanaIntelliSearchContentConditionInput
  ): Promise<UpdateAnaIntelliSearchContentMutation> {
    const statement = `mutation UpdateAnaIntelliSearchContent($input: UpdateAnaIntelliSearchContentInput!, $condition: ModelanaIntelliSearchContentConditionInput) {
        updateAnaIntelliSearchContent(input: $input, condition: $condition) {
          __typename
          key
          value
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAnaIntelliSearchContentMutation>(
      response.data.updateAnaIntelliSearchContent
    );
  }
  async DeleteAnaIntelliSearchContent(
    input: DeleteAnaIntelliSearchContentInput,
    condition?: ModelanaIntelliSearchContentConditionInput
  ): Promise<DeleteAnaIntelliSearchContentMutation> {
    const statement = `mutation DeleteAnaIntelliSearchContent($input: DeleteAnaIntelliSearchContentInput!, $condition: ModelanaIntelliSearchContentConditionInput) {
        deleteAnaIntelliSearchContent(input: $input, condition: $condition) {
          __typename
          key
          value
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAnaIntelliSearchContentMutation>(
      response.data.deleteAnaIntelliSearchContent
    );
  }
  async CreateAnaSourceAppCode(
    input: CreateAnaSourceAppCodeInput,
    condition?: ModelanaSourceAppCodeConditionInput
  ): Promise<CreateAnaSourceAppCodeMutation> {
    const statement = `mutation CreateAnaSourceAppCode($input: CreateAnaSourceAppCodeInput!, $condition: ModelanaSourceAppCodeConditionInput) {
        createAnaSourceAppCode(input: $input, condition: $condition) {
          __typename
          src_app_cd
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAnaSourceAppCodeMutation>response.data.createAnaSourceAppCode;
  }
  async UpdateAnaSourceAppCode(
    input: UpdateAnaSourceAppCodeInput,
    condition?: ModelanaSourceAppCodeConditionInput
  ): Promise<UpdateAnaSourceAppCodeMutation> {
    const statement = `mutation UpdateAnaSourceAppCode($input: UpdateAnaSourceAppCodeInput!, $condition: ModelanaSourceAppCodeConditionInput) {
        updateAnaSourceAppCode(input: $input, condition: $condition) {
          __typename
          src_app_cd
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAnaSourceAppCodeMutation>response.data.updateAnaSourceAppCode;
  }
  async DeleteAnaSourceAppCode(
    input: DeleteAnaSourceAppCodeInput,
    condition?: ModelanaSourceAppCodeConditionInput
  ): Promise<DeleteAnaSourceAppCodeMutation> {
    const statement = `mutation DeleteAnaSourceAppCode($input: DeleteAnaSourceAppCodeInput!, $condition: ModelanaSourceAppCodeConditionInput) {
        deleteAnaSourceAppCode(input: $input, condition: $condition) {
          __typename
          src_app_cd
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAnaSourceAppCodeMutation>response.data.deleteAnaSourceAppCode;
  }
  async CreateAnaClientCodeModel(
    input: CreateAnaClientCodeModelInput,
    condition?: ModelanaClientCodeModelConditionInput
  ): Promise<CreateAnaClientCodeModelMutation> {
    const statement = `mutation CreateAnaClientCodeModel($input: CreateAnaClientCodeModelInput!, $condition: ModelanaClientCodeModelConditionInput) {
        createAnaClientCodeModel(input: $input, condition: $condition) {
          __typename
          clnt_cd
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAnaClientCodeModelMutation>(
      response.data.createAnaClientCodeModel
    );
  }
  async UpdateAnaClientCodeModel(
    input: UpdateAnaClientCodeModelInput,
    condition?: ModelanaClientCodeModelConditionInput
  ): Promise<UpdateAnaClientCodeModelMutation> {
    const statement = `mutation UpdateAnaClientCodeModel($input: UpdateAnaClientCodeModelInput!, $condition: ModelanaClientCodeModelConditionInput) {
        updateAnaClientCodeModel(input: $input, condition: $condition) {
          __typename
          clnt_cd
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAnaClientCodeModelMutation>(
      response.data.updateAnaClientCodeModel
    );
  }
  async DeleteAnaClientCodeModel(
    input: DeleteAnaClientCodeModelInput,
    condition?: ModelanaClientCodeModelConditionInput
  ): Promise<DeleteAnaClientCodeModelMutation> {
    const statement = `mutation DeleteAnaClientCodeModel($input: DeleteAnaClientCodeModelInput!, $condition: ModelanaClientCodeModelConditionInput) {
        deleteAnaClientCodeModel(input: $input, condition: $condition) {
          __typename
          clnt_cd
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAnaClientCodeModelMutation>(
      response.data.deleteAnaClientCodeModel
    );
  }
  async CreateAnaCloudStackModel(
    input: CreateAnaCloudStackModelInput,
    condition?: ModelanaCloudStackModelConditionInput
  ): Promise<CreateAnaCloudStackModelMutation> {
    const statement = `mutation CreateAnaCloudStackModel($input: CreateAnaCloudStackModelInput!, $condition: ModelanaCloudStackModelConditionInput) {
        createAnaCloudStackModel(input: $input, condition: $condition) {
          __typename
          cloud_stack_nm
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAnaCloudStackModelMutation>(
      response.data.createAnaCloudStackModel
    );
  }
  async UpdateAnaCloudStackModel(
    input: UpdateAnaCloudStackModelInput,
    condition?: ModelanaCloudStackModelConditionInput
  ): Promise<UpdateAnaCloudStackModelMutation> {
    const statement = `mutation UpdateAnaCloudStackModel($input: UpdateAnaCloudStackModelInput!, $condition: ModelanaCloudStackModelConditionInput) {
        updateAnaCloudStackModel(input: $input, condition: $condition) {
          __typename
          cloud_stack_nm
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAnaCloudStackModelMutation>(
      response.data.updateAnaCloudStackModel
    );
  }
  async DeleteAnaCloudStackModel(
    input: DeleteAnaCloudStackModelInput,
    condition?: ModelanaCloudStackModelConditionInput
  ): Promise<DeleteAnaCloudStackModelMutation> {
    const statement = `mutation DeleteAnaCloudStackModel($input: DeleteAnaCloudStackModelInput!, $condition: ModelanaCloudStackModelConditionInput) {
        deleteAnaCloudStackModel(input: $input, condition: $condition) {
          __typename
          cloud_stack_nm
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAnaCloudStackModelMutation>(
      response.data.deleteAnaCloudStackModel
    );
  }
  async ListPgCIs(
    limit?: number,
    offset?: number,
    fields?: Array<string | null>,
    filter?: ModelCIFilterInput,
    newfilter?: ModelCIFilterInput
  ): Promise<ListPgCIsQuery> {
    const statement = `query ListPgCIs($limit: Int, $offset: Int, $fields: [String], $filter: ModelCIFilterInput, $newfilter: ModelCIFilterInput) {
        listPgCIs(limit: $limit, offset: $offset, fields: $fields, filter: $filter, newfilter: $newfilter) {
          __typename
          asset_sk
          asset_tag_nm
          asgn_utc_ts
          asgne_cntct_nm
          asgne_cntct_sk
          can_prt_fg
          chg_appv_wkgp_nm
          chg_appv_wkgp_sk
          ci_ky
          ci_nm
          ci_sk
          cls_nm
          cmnt_tx
          co_ky
          co_nm
          co_sk
          corltn_id
          cst_ctr_org_nm
          cst_ctr_org_sk
          data_dmn_nm
          data_dmn_sk
          dept_nm
          dept_sk
          dlvr_dt_utc_ts
          dns_dmn_nm
          dscvr_src_nm
          fault_ct
          fqdn_nm
          frst_dscvr_utc_ts
          gl_acct_nm
          instl_stat_nm
          instl_utc_ts
          inv_nr_nm
          ip_addr_nm
          last_dscvr_utc_ts
          loc_nm
          loc_sk
          mac_addr_nm
          maint_sch_nm
          maint_sch_sk
          mdl_id
          mdl_nr
          mdl_sk
          mfr_nm
          mng_dmn_fg
          mntr_fg
          open_by_agnt_cntct_nm
          oprtnl_catg_tier_1_nm
          oprtnl_catg_tier_2_nm
          oprtnl_catg_tier_3_nm
          oprtnl_stat_nm
          owned_by_cntct_nm
          owned_by_cntct_sk
          purch_ord_nr
          purch_utc_ts
          sch_nm
          sch_sk
          src_crt_utc_ts
          src_upd_utc_ts
          sht_dn_tx
          splr_nm
          splr_sk
          srl_nr_nm
          strt_utc_ts
          suprt_by_cntct_nm
          suprt_by_cntct_sk
          suprt_wkgp_nm
          suprt_wkgrp_sk
          u_flr_nm
          u_host_nm
          u_mtch_type_nm
          u_mntr_nm
          u_no_scan_rsn_nm
          u_rm_nm
          u_seat_nm
          u_shrd_fg
          u_suprt_nm
          u_use_for_nm
          upd_by_cntct_nm
          warr_exp_utc_ts
          lgcl_del_fg
          src_sys_ky
          src_sys_nm
          d1_crt_utc_ts
          d2_chksum_tx
          d2_crt_job_id
          d2_crt_utc_ts
          d2_load_cd
          src_clnt_cd
          asset_nm
          avail_tgt_pc
          lvl_of_svc_nm
          bus_srvc_ci_nm
          bus_srvc_clsfc_nm
          bus_crtclty_nm
          suprt_usr_wkgp_nm
          mng_by_cntct_nm
          crt_by_cntct_nm
          attr_tx
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (offset) {
      gqlAPIServiceArguments.offset = offset;
    }
    if (fields) {
      gqlAPIServiceArguments.fields = fields;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (newfilter) {
      gqlAPIServiceArguments.newfilter = newfilter;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPgCIsQuery>response.data.listPgCIs;
  }
  async LovPgCIs(fields?: Array<string | null>): Promise<LovPgCIsQuery> {
    const statement = `query LovPgCIs($fields: [String]) {
        lovPgCIs(fields: $fields) {
          __typename
          cls_nm
          instl_stat_nm
          u_use_for_nm
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (fields) {
      gqlAPIServiceArguments.fields = fields;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <LovPgCIsQuery>response.data.lovPgCIs;
  }
  async ListSourceAppCode(
    fields?: Array<string | null>
  ): Promise<ListSourceAppCodeQuery> {
    const statement = `query ListSourceAppCode($fields: [String]) {
        listSourceAppCode(fields: $fields) {
          __typename
          src_app_cd
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (fields) {
      gqlAPIServiceArguments.fields = fields;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSourceAppCodeQuery>response.data.listSourceAppCode;
  }
  async ListClientCode(
    fields?: Array<string | null>
  ): Promise<ListClientCodeQuery> {
    const statement = `query ListClientCode($fields: [String]) {
        listClientCode(fields: $fields) {
          __typename
          clnt_cd
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (fields) {
      gqlAPIServiceArguments.fields = fields;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListClientCodeQuery>response.data.listClientCode;
  }
  async ListCloudStack(
    fields?: Array<string | null>
  ): Promise<ListCloudStackQuery> {
    const statement = `query ListCloudStack($fields: [String]) {
        listCloudStack(fields: $fields) {
          __typename
          cloud_stack_nm
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (fields) {
      gqlAPIServiceArguments.fields = fields;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCloudStackQuery>response.data.listCloudStack;
  }
  async ListPgCiUpstream(
    limit?: number,
    offset?: number,
    fields?: Array<string | null>,
    filter?: ModelCIRelationFilterInput
  ): Promise<ListPgCiUpstreamQuery> {
    const statement = `query ListPgCiUpstream($limit: Int, $offset: Int, $fields: [String], $filter: ModelCIRelationFilterInput) {
        listPgCIUpstream(limit: $limit, offset: $offset, fields: $fields, filter: $filter) {
          __typename
          parnt_ci_nm
          ci_relshp_type_nm
          child_ci_nm
          parnt_ci_ky
          cls_nm
          instl_stat_nm
          u_use_for_nm
          corltn_id
          co_sk
          co_nm
          child_ci_ky
          lgcl_del_fg
          ci_nm
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (offset) {
      gqlAPIServiceArguments.offset = offset;
    }
    if (fields) {
      gqlAPIServiceArguments.fields = fields;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPgCiUpstreamQuery>response.data.listPgCIUpstream;
  }
  async ListPgCiDownstream(
    limit?: number,
    offset?: number,
    fields?: Array<string | null>,
    filter?: ModelCIRelationFilterInput
  ): Promise<ListPgCiDownstreamQuery> {
    const statement = `query ListPgCiDownstream($limit: Int, $offset: Int, $fields: [String], $filter: ModelCIRelationFilterInput) {
        listPgCIDownstream(limit: $limit, offset: $offset, fields: $fields, filter: $filter) {
          __typename
          parnt_ci_nm
          ci_relshp_type_nm
          child_ci_nm
          parnt_ci_ky
          cls_nm
          instl_stat_nm
          u_use_for_nm
          corltn_id
          co_sk
          co_nm
          child_ci_ky
          lgcl_del_fg
          ci_nm
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (offset) {
      gqlAPIServiceArguments.offset = offset;
    }
    if (fields) {
      gqlAPIServiceArguments.fields = fields;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPgCiDownstreamQuery>response.data.listPgCIDownstream;
  }
  async ListPgCiDetails(
    limit?: number,
    offset?: number,
    fields?: Array<string | null>,
    filter?: ModelCIRelationFilterInput
  ): Promise<ListPgCiDetailsQuery> {
    const statement = `query ListPgCiDetails($limit: Int, $offset: Int, $fields: [String], $filter: ModelCIRelationFilterInput) {
        listPgCIDetails(limit: $limit, offset: $offset, fields: $fields, filter: $filter) {
          __typename
          ci_nm
          instl_stat_nm
          u_use_for_nm
          cls_nm
          co_nm
          co_ky
          loc_nm
          suprt_by_cntct_nm
          asgne_cntct_nm
          suprt_wkgp_nm
          corltn_id
          asset_tag_nm
          mntr_fg
          u_suprt_nm
          owned_by_cntct_nm
          src_crt_utc_ts
          sht_dn_tx
          upd_by_cntct_nm
          src_upd_utc_ts
          cmnt_tx
          dept_nm
          lgcl_del_fg
          parnt_ci_nm
          ci_relshp_type_nm
          child_ci_nm
          parnt_ci_ky
          child_ci_ky
          bus_srvc_ci_nm
          bus_srvc_clsfc_nm
          bus_crtclty_nm
          suprt_usr_wkgp_nm
          mng_by_cntct_nm
          crt_by_cntct_nm
          attr_tx
          it_owner_org
          it_support_org
          bus_owner_org
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (offset) {
      gqlAPIServiceArguments.offset = offset;
    }
    if (fields) {
      gqlAPIServiceArguments.fields = fields;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPgCiDetailsQuery>response.data.listPgCIDetails;
  }
  async ListPgCiOrgs(
    limit?: number,
    offset?: number,
    fields?: Array<string | null>,
    filter?: ModelCIRelationFilterInput
  ): Promise<ListPgCiOrgsQuery> {
    const statement = `query ListPgCiOrgs($limit: Int, $offset: Int, $fields: [String], $filter: ModelCIRelationFilterInput) {
        listPgCIOrgs(limit: $limit, offset: $offset, fields: $fields, filter: $filter) {
          __typename
          it_owner_org
          it_support_org
          bus_owner_org
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (offset) {
      gqlAPIServiceArguments.offset = offset;
    }
    if (fields) {
      gqlAPIServiceArguments.fields = fields;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPgCiOrgsQuery>response.data.listPgCIOrgs;
  }
  async GetAnaIntelliSearchContent(
    id: string
  ): Promise<GetAnaIntelliSearchContentQuery> {
    const statement = `query GetAnaIntelliSearchContent($id: ID!) {
        getAnaIntelliSearchContent(id: $id) {
          __typename
          key
          value
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAnaIntelliSearchContentQuery>(
      response.data.getAnaIntelliSearchContent
    );
  }
  async ListAnaIntelliSearchContents(
    filter?: ModelanaIntelliSearchContentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAnaIntelliSearchContentsQuery> {
    const statement = `query ListAnaIntelliSearchContents($filter: ModelanaIntelliSearchContentFilterInput, $limit: Int, $nextToken: String) {
        listAnaIntelliSearchContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            key
            value
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAnaIntelliSearchContentsQuery>(
      response.data.listAnaIntelliSearchContents
    );
  }
  async GetAnaSourceAppCode(id: string): Promise<GetAnaSourceAppCodeQuery> {
    const statement = `query GetAnaSourceAppCode($id: ID!) {
        getAnaSourceAppCode(id: $id) {
          __typename
          src_app_cd
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAnaSourceAppCodeQuery>response.data.getAnaSourceAppCode;
  }
  async ListAnaSourceAppCodes(
    filter?: ModelanaSourceAppCodeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAnaSourceAppCodesQuery> {
    const statement = `query ListAnaSourceAppCodes($filter: ModelanaSourceAppCodeFilterInput, $limit: Int, $nextToken: String) {
        listAnaSourceAppCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            src_app_cd
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAnaSourceAppCodesQuery>response.data.listAnaSourceAppCodes;
  }
  async GetAnaClientCodeModel(id: string): Promise<GetAnaClientCodeModelQuery> {
    const statement = `query GetAnaClientCodeModel($id: ID!) {
        getAnaClientCodeModel(id: $id) {
          __typename
          clnt_cd
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAnaClientCodeModelQuery>response.data.getAnaClientCodeModel;
  }
  async ListAnaClientCodeModels(
    filter?: ModelanaClientCodeModelFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAnaClientCodeModelsQuery> {
    const statement = `query ListAnaClientCodeModels($filter: ModelanaClientCodeModelFilterInput, $limit: Int, $nextToken: String) {
        listAnaClientCodeModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            clnt_cd
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAnaClientCodeModelsQuery>response.data.listAnaClientCodeModels;
  }
  async GetAnaCloudStackModel(id: string): Promise<GetAnaCloudStackModelQuery> {
    const statement = `query GetAnaCloudStackModel($id: ID!) {
        getAnaCloudStackModel(id: $id) {
          __typename
          cloud_stack_nm
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAnaCloudStackModelQuery>response.data.getAnaCloudStackModel;
  }
  async ListAnaCloudStackModels(
    filter?: ModelanaCloudStackModelFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAnaCloudStackModelsQuery> {
    const statement = `query ListAnaCloudStackModels($filter: ModelanaCloudStackModelFilterInput, $limit: Int, $nextToken: String) {
        listAnaCloudStackModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            cloud_stack_nm
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAnaCloudStackModelsQuery>response.data.listAnaCloudStackModels;
  }
  OnCreateAnaIntelliSearchContentListener: Observable<
    OnCreateAnaIntelliSearchContentSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateAnaIntelliSearchContent {
        onCreateAnaIntelliSearchContent {
          __typename
          key
          value
        }
      }`
    )
  ) as Observable<OnCreateAnaIntelliSearchContentSubscription>;

  OnUpdateAnaIntelliSearchContentListener: Observable<
    OnUpdateAnaIntelliSearchContentSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAnaIntelliSearchContent {
        onUpdateAnaIntelliSearchContent {
          __typename
          key
          value
        }
      }`
    )
  ) as Observable<OnUpdateAnaIntelliSearchContentSubscription>;

  OnDeleteAnaIntelliSearchContentListener: Observable<
    OnDeleteAnaIntelliSearchContentSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAnaIntelliSearchContent {
        onDeleteAnaIntelliSearchContent {
          __typename
          key
          value
        }
      }`
    )
  ) as Observable<OnDeleteAnaIntelliSearchContentSubscription>;

  OnCreateAnaSourceAppCodeListener: Observable<
    OnCreateAnaSourceAppCodeSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateAnaSourceAppCode {
        onCreateAnaSourceAppCode {
          __typename
          src_app_cd
        }
      }`
    )
  ) as Observable<OnCreateAnaSourceAppCodeSubscription>;

  OnUpdateAnaSourceAppCodeListener: Observable<
    OnUpdateAnaSourceAppCodeSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAnaSourceAppCode {
        onUpdateAnaSourceAppCode {
          __typename
          src_app_cd
        }
      }`
    )
  ) as Observable<OnUpdateAnaSourceAppCodeSubscription>;

  OnDeleteAnaSourceAppCodeListener: Observable<
    OnDeleteAnaSourceAppCodeSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAnaSourceAppCode {
        onDeleteAnaSourceAppCode {
          __typename
          src_app_cd
        }
      }`
    )
  ) as Observable<OnDeleteAnaSourceAppCodeSubscription>;

  OnCreateAnaClientCodeModelListener: Observable<
    OnCreateAnaClientCodeModelSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateAnaClientCodeModel {
        onCreateAnaClientCodeModel {
          __typename
          clnt_cd
        }
      }`
    )
  ) as Observable<OnCreateAnaClientCodeModelSubscription>;

  OnUpdateAnaClientCodeModelListener: Observable<
    OnUpdateAnaClientCodeModelSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAnaClientCodeModel {
        onUpdateAnaClientCodeModel {
          __typename
          clnt_cd
        }
      }`
    )
  ) as Observable<OnUpdateAnaClientCodeModelSubscription>;

  OnDeleteAnaClientCodeModelListener: Observable<
    OnDeleteAnaClientCodeModelSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAnaClientCodeModel {
        onDeleteAnaClientCodeModel {
          __typename
          clnt_cd
        }
      }`
    )
  ) as Observable<OnDeleteAnaClientCodeModelSubscription>;

  OnCreateAnaCloudStackModelListener: Observable<
    OnCreateAnaCloudStackModelSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateAnaCloudStackModel {
        onCreateAnaCloudStackModel {
          __typename
          cloud_stack_nm
        }
      }`
    )
  ) as Observable<OnCreateAnaCloudStackModelSubscription>;

  OnUpdateAnaCloudStackModelListener: Observable<
    OnUpdateAnaCloudStackModelSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAnaCloudStackModel {
        onUpdateAnaCloudStackModel {
          __typename
          cloud_stack_nm
        }
      }`
    )
  ) as Observable<OnUpdateAnaCloudStackModelSubscription>;

  OnDeleteAnaCloudStackModelListener: Observable<
    OnDeleteAnaCloudStackModelSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAnaCloudStackModel {
        onDeleteAnaCloudStackModel {
          __typename
          cloud_stack_nm
        }
      }`
    )
  ) as Observable<OnDeleteAnaCloudStackModelSubscription>;
}
