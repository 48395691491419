import { Component, OnInit } from '@angular/core';
import { AmplifyAPIServiceService } from "./../../services/amplify-apiservice.service"
import { APIService } from "./../../API.service"
import { JsonpInterceptor } from '@angular/common/http';
@Component({
  selector: 'app-ci-custom-query',
  templateUrl: './ci-custom-query.component.html',
  styleUrls: ['./ci-custom-query.component.scss']
})
export class CiCustomQueryComponent implements OnInit {
  allCIs: any = [];
  ciUpstreamRelation: any = [];
  ciDownsreamRelation: any = [];
  selected
  selectedOptions
  constructor(private api: AmplifyAPIServiceService, private apiS: APIService) { }

  async ngOnInit() {
    //this.getAllCIs()
    this.saveUpdateDynamoDbHome()
  }

  async getSelectValues() {
    let lovName = "cls_nm"
    let arrLovs = await this.api.LovPgCIs(lovName, [lovName]);
    arrLovs.forEach(element => {
      //console.log(element[lovName])
      this.allCIs.push({ display: element[lovName], value: element[lovName] })
    });
  }

  async getAllCIs() {

    //let cidetails = await this.apiS.ListPgCiDetails()
    //console.log(cidetails);
    //let statement = "  ci_nm instl_stat_nm cls_nm co_nm asset_tag_nm loc_nm asgne_cntct_nm owned_by_cntct_nm suprt_wkgp_nm "
    //{ asset_tag_nm: { contains: "00" } }
    //{ can_prt_fg: { eq: false } }
    //{ OR : [  {asset_tag_nm: { contains: "00" } }, { can_prt_fg: { eq: false }  }]
    //{ "and": [{ "ci_nm": { "eq": "subnet-9c6760fb" } }, { "and": [{ can_prt_fg: { eq: true } }, { "cls_nm": { "eq": "AWS Resource" } }] }] }
    // { and: [{ "cls_nm": { "ne": "Computer" } }, { and: [{ asset_tag_nm: { contains: "T00" } }, { can_prt_fg: { eq: false } }] }] }
    //{ and: [{ cls_nm: { ne: "Computer" } }, { or: [{ asset_tag_nm: { contains: "T00" } }, { can_prt_fg: { eq: false } }] }] }
    // const respons     e = await this.api.ListPgCIs(statement, 100, 0,
    //   ["ci_nm", "instl_stat_nm", "cls_nm", "co_nm", "asset_tag_nm", "loc_nm", "asgne_cntct_nm", "owned_by_cntct_nm", "suprt_wkgp_nm"],
    //   { or: [{ cls_nm: { eq: "Computer" } }, { and: [{ asset_tag_nm: { contains: "T00" } }, { can_prt_fg: { eq: false } }] }] }
    // );
    // //console.log(response);
    // this.allCIs = response;

    //this.ciUpstreamRelation = await this.apiS.ListPgCiUpstream(50, 0, null, { and: [{ child_ci_ky: { eq: "ef3e0c8f72c8afe6cc77b6506f94ed3f" } }, { lgcl_del_fg: { eq: false } }] })

    // let statemet = ""
    // this.ciUpstreamRelation = this.api.ListPgCiUpstream("parnt_ci_nm,ci_relshp_type_nm,child_ci_nm,parnt_ci_ky,cls_nm,instl_stat_nm,u_use_for_nm,corltn_id,co_sk,co_nm,child_ci_ky,lgcl_del_fg,ci_nm", 300, 0, ["parnt_ci_nm", "ci_relshp_type_nm", "child_ci_nm", "parnt_ci_ky", "cls_nm", "instl_stat_nm", "u_use_for_nm", "corltn_id", "co_sk", "co_nm", "child_ci_ky", "lgcl_del_fg", "ci_nm"], { and: [{ child_ci_ky: { eq: "ef3e0c8f72c8afe6cc77b6506f94ed3f" } }, { lgcl_del_fg: { eq: false } }] })
    // console.log("upstream ci")
    // console.log(JSON.stringify(this.ciUpstreamRelation))
    // this.ciDownsreamRelation = await this.api.ListPgCiDownstream("parnt_ci_nm,ci_relshp_type_nm,child_ci_nm,parnt_ci_ky,cls_nm,instl_stat_nm,u_use_for_nm,corltn_id,co_sk,co_nm,child_ci_ky,lgcl_del_fg,ci_nm", 50, 0, null, { and: [{ parnt_ci_ky: { eq: "140110acd6edd305702eb54b675babfd" } }, { lgcl_del_fg: { eq: false } }] })
    // console.log("downstream ci")
    // console.log(JSON.stringify(this.ciDownsreamRelation))
    // // let statemet = ""
    // this.ciUpstreamRelation = this.api.ListPgCiUpstream("parnt_ci_nm,ci_relshp_type_nm,child_ci_nm,parnt_ci_ky,cls_nm,instl_stat_nm,u_use_for_nm,corltn_id,co_sk,co_nm,child_ci_ky,lgcl_del_fg,ci_nm", 300, 0, ["parnt_ci_nm", "ci_relshp_type_nm", "child_ci_nm", "parnt_ci_ky", "cls_nm", "instl_stat_nm", "u_use_for_nm", "corltn_id", "co_sk", "co_nm", "child_ci_ky", "lgcl_del_fg", "ci_nm"], { and: [{ child_ci_ky: { eq: "140110acd6edd305702eb54b675babfd" } }, { lgcl_del_fg: { eq: false } }] })
    // console.log("upstream ci")
    // console.log(JSON.stringify(this.ciUpstreamRelation))


  }

  getSelectedOptions(selected) {
    this.selected = selected;
  }

  async saveUpdateDynamoDbHome() {
    let relNotes = "<span>This is the first phase of Intelligent Search, The key functionalities includes -</span> " +
      "<ol><li>" +
      "Ability to quickly find a Configuration Item, such as Business Application, Server, Database, etc." +
      "</li><li>" +
      "View all CI details coming from Service Now, on the same page." +
      "</li></ol>" +
      "<span class='futureScopeSpan'>Future Scope</span>" +
      "<ol><li>" +
      "Provide export to excel functionality for report" +
      "</li></ol>" +
      "<span class='tentativeReleaseSpan'>Tentative Release - August 2020</span>"
    console.log(relNotes)
    let contactInfo = [{ userName: "Niladri Chowdhury", email: "niladri.chowdhury@dxc.com", mailLink: "mailto:niladri.chowdhury@dxc.com" },
    { userName: "Oleg Rakcheev", email: "oleg.rakcheev@dxc.com", mailLink: "mailto:oleg.rakcheev@dxc.com" },
    { userName: "Praveen Krishnamurthy", email: "praveen.krishnamurthy@dxc.com", mailLink: "mailto:praveen.krishnamurthy@dxc.com" },
    { userName: "Rafael Gomez", email: "rgomez45@dxc.com", mailLink: "mailto:rgomez45@dxc.com" },
    { userName: "Dave Marking", email: "dave.marking@dxc.com", mailLink: "mailto:dave.marking@dxc.com" },
    { userName: "Deepshree Sharma", email: "deepshree.sharma@dxc.com", mailLink: "mailto:deepshree.sharma@dxc.com" }]

    let userDocs = [{ docName: "Project Architecture", docLink: "https://github.dxc.com/pages/Platform-DXC/intelligence-search-docs/Documentation/Product/Solutioning/" }]

    console.log(JSON.stringify(contactInfo));
    //let userDoc = [{ docName: "Architechture", docLink: "https://github.dxc.com/Platform-DXC/intelligence-search/tree/master/docs/architecture" }]

    //let res = await this.apiS.UpdateAnaIntellisearch({ key: "releaseNotes", value: JSON.stringify(relNotes) }, { key: { eq: "releaseNotes" } });

    let res = await this.apiS.CreateAnaIntelliSearchContent({ key: "releaseNotes", value: relNotes })
    console.log(res);
    let res1 = await this.apiS.CreateAnaIntelliSearchContent({ key: "contactInfo", value: JSON.stringify(contactInfo) })
    console.log(res1);
    let res2 = await this.apiS.CreateAnaIntelliSearchContent({ key: "userDoc", value: JSON.stringify(userDocs) })
    //console.log\("DynamoDB Result")
    console.log(res2);
  }
}
