import { Component, OnChanges } from '@angular/core';
import { AuthService } from './auth.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Auth } from 'aws-amplify';
import { async } from '@angular/core/testing';
import { CookieService } from "ngx-cookie-service";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public isLoggedIn: boolean = false;
  public isLoginLoaded: boolean = false;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';


  constructor(public auth: AuthService, private idle: Idle, private keepalive: Keepalive, private cookieService: CookieService) {
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(5000);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(5000);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      //console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      //console.log(this.idleState);
      //this.router.navigate(['/']);
      this.handleSignOut();


    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      //console.log(this.idleState);
      // this.childModal.show();
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      //console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  async handleSignOut() {
    Auth.signOut();
    //this.auth.user.
    // localStorage.setItem("isloggedIn", "false");
    localStorage.clear();
    sessionStorage.clear();
    this.deleteAllCookies();
  }
  ngOnInit() { }

  deleteAllCookies() {
    //console.log(this.cookieService.get("cognito"))
    //console.log(this.cookieService.get("XSRF-TOKEN"))
    this.cookieService.delete("cognito", "/", null, false)
    this.cookieService.delete("XSRF-TOKEN", "/")
    //console.log(this.cookieService.get("cognito"))
    //console.log(this.cookieService.get("XSRF-TOKEN"))
    // var cookies = window.cooki
    // console.log(cookies)
    // for (var i = 0; i < cookies.length; i++) {
    //   var cookie = cookies[i];
    //   console.log("inside" + cookie + "dk");

    //   var eqPos = cookie.indexOf("=");
    //   var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    //   document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    // }
  }

  isUserLoggedIn(): boolean {
    if (localStorage.getItem("isloggedIn") == "true") {
      return true;
    } else {
      false;
    }
  }
}
