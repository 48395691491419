import { CiCustomQueryComponent } from './../ci-custom-query.component';
import { CiCustomQueryRoutingModule } from './../ci-custom-query-routing/ci-custom-query-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [CiCustomQueryComponent],
  imports: [
    CommonModule,
    CiCustomQueryRoutingModule
  ]
})
export class CiCustomQueryModule { }
