import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss']
})
export class InfoCardComponent implements OnInit {

  @Input() server: any = {};
  @Input() index: number;

  @Output() selectedServer: EventEmitter<number>;

  constructor(private router: Router) { 
    this.selectedServer = new EventEmitter();
  }

  ngOnInit() {
  }

  seeServer() {
    // console.log(this.index);
    this.router.navigate(['/server', this.index]);
  }

}
