import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { APIRequestHelper } from "../../../helpers/api-request-helper"
import { AmplifyAPIServiceService } from "../../../services/amplify-apiservice.service"
import { ProgressbarService } from "../../../services/progressbar.service"
import { TableUtilsModule } from "../ci-details/table-utils.module"
import { AuthService } from '../../../auth.service';

@Component({
  selector: 'app-ci-details',
  templateUrl: './ci-details.component.html',
  styleUrls: ['./ci-details.component.scss']
})
export class CiDetailsComponent implements OnInit {
  private _ciKey: string;
  public CIName = { field: "ci_nm", value: " " };
  showDetail = false;
  showReport=true;
  displayColumns =
    [
      { displayName: "CI Name", field: "ci_nm" },
      { displayName: "Install Status", field: "instl_stat_nm" },
      { displayName: "Environment", field: "u_use_for_nm" },
      { displayName: "Class", field: "cls_nm" },
      { displayName: "Service Classification", field: "bus_srvc_clsfc_nm" },
      { displayName: "Company", field: "co_nm" },
      { displayName: "Location", field: "loc_nm" },
      { displayName: "Supported By", field: "suprt_by_cntct_nm" },
      { displayName: "Supported By Email", field: "sup_by_email" },
      { displayName: "Assigned To", field: "asgne_cntct_nm" },
      { displayName: "Support Group", field: "suprt_usr_wkgp_nm" },
      { displayName: "Correlation Id", field: "corltn_id" },
      { displayName: "Asset Tag", field: "asset_tag_nm" },
      { displayName: "Business Criticality", field: "bus_crtclty_nm" },
      { displayName: "User Supported", field: "suprt_usr_wkgp_nm" },
      { displayName: "Support Workgroup", field: "suprt_wkgp_nm" },
      { displayName: "Monitored", field: "mntr_fg" },
      { displayName: "Supported", field: "u_suprt_nm" },
      { displayName: "Owned By", field: "owned_by_cntct_nm" },
      { displayName: "Owned By Email", field: "owned_by_email" },
      { displayName: "Managed By", field: "mng_by_cntct_nm" },
      { displayName: "Created By", field: "crt_by_cntct_nm" },
      { displayName: "Created", field: "src_crt_utc_ts" },
      { displayName: "Description", field: "sht_dn_tx" },
      { displayName: "Updated By", field: "upd_by_cntct_nm" },
      { displayName: "Updated", field: "src_upd_utc_ts" },
      { displayName: "Comment", field: "cmnt_tx" },
      { displayName: "Attribute", field: "attr_tx" },
      { displayName: "Department", field: "dept_nm" }
    ];
  relDisplayColumns = [{ displayName: "Class", field: "cls_nm", hide: false, displayOrder: 1, link: false },
  { displayName: "Parent CI", field: "parnt_ci_nm", hide: false, displayOrder: 2, link: true, class: "link" },
  { displayName: "Child CI", field: "child_ci_nm", hide: false, displayOrder: 2, link: true, class: "link" },
  { displayName: "Install Status", field: "instl_stat_nm", hide: false, displayOrder: 3, link: false },
  { displayName: "Rel Type", field: "ci_relshp_type_nm", hide: false, displayOrder: 4, link: false },
  { displayName: "CI", field: "ci_nm", hide: true, link: false },
  { displayName: "Environment", field: "u_use_for_nm", hide: false, displayOrder: 5, link: false },
  { displayName: "Correlation Id", field: "corltn_id", hide: false, displayOrder: 6, link: false },
  { displayName: "Child CI Key", field: "child_ci_ky", hide: true, displayOrder: 0, link: false },
  { displayName: "Parent CI Key", field: "parnt_ci_ky", hide: true, displayOrder: 0, link: false }];

  orgDisplayColumns = [{ displayName: "IT Owner Organisation", field: "it_owner_org" },
  { displayName: "IT Support Organisation", field: "it_support_org" },
  { displayName: "Business Owner Org", field: "bus_owner_org" }];

  TopLeftColumns = [{ headerName: "", value: "", field: "ci_nm" }, { headerName: "", value: "", field: "instl_stat_nm" }, { headerName: "", value: "", field: "u_use_for_nm" }, { headerName: "", value: "", field: "cls_nm" }, { headerName: "", value: "", field: "bus_srvc_clsfc_nm" }, { headerName: "", value: "", field: "bus_crtclty_nm" }];
 
  TopRightColumns = [{ headerName: "", value: "", field: "corltn_id" }, { headerName: "", value: "", field: "loc_nm" }, { headerName: "", value: "", field: "src_crt_utc_ts" }, { headerName: "", value: "", field: "src_upd_utc_ts" }, { headerName: "", value: "", field: "asgne_cntct_nm" }, { headerName: "", value: "", field: "suprt_wkgp_nm" }];
  
  SummaryLeft = [{ headerName: "", value: "", field: "sht_dn_tx" }, { headerName: "", value: "", field: "asset_tag_nm" }, { headerName: "", value: "", field: "cmnt_tx" }, { headerName: "", value: "", field: "dept_nm" }, { headerName: "", value: "", field: "u_suprt_nm" }, { headerName: "", value: "", field: "mntr_fg" }, { headerName: "", value: "", field: "suprt_usr_wkgp_nm" }];

  SummaryOrg = [{ headerName: "", value: "", field: "it_owner_org" }, { headerName: "", value: "", field: "it_support_org" }, { headerName: "", value: "", field: "bus_owner_org" }];

  SummaryContact = [{ headerName: "", value: "", field: "owned_by_cntct_nm" }, { headerName: "", value: "", field: "owned_by_email" },{ headerName: "", value: "", field: "suprt_by_cntct_nm" },{ headerName: "", value: "", field: "sup_by_email" }, { headerName: "", value: "", field: "mng_by_cntct_nm" }, { headerName: "", value: "", field: "upd_by_cntct_nm" }, { headerName: "", value: "", field: "crt_by_cntct_nm" },]
  CiDetails = [];
  CiUpstream = [];
  CiDownstream = [];
  CiOrgs = [];
  CompanyName:string="";
  

  constructor(private actRoute: ActivatedRoute, private api: AmplifyAPIServiceService, private reqHelper: APIRequestHelper, private progressbarService: ProgressbarService,private auth:AuthService) {

  }

  ngOnInit() {
    this.actRoute.params.subscribe(params => {
      this.resetDatatables()
      this._ciKey = params.id
      this.getCIDetailByCIKey()
    });
    this.CompanyName=this.auth.getAltCompanyCode();
  }

  resetDatatables() {
    this.TopLeftColumns = [{ headerName: "", value: "", field: "ci_nm" }, { headerName: "", value: "", field: "instl_stat_nm" }, { headerName: "", value: "", field: "u_use_for_nm" }, { headerName: "", value: "", field: "cls_nm" }, { headerName: "", value: "", field: "bus_srvc_clsfc_nm" }, { headerName: "", value: "", field: "bus_crtclty_nm" }];
    this.TopRightColumns = [{ headerName: "", value: "", field: "corltn_id" }, { headerName: "", value: "", field: "loc_nm" }, { headerName: "", value: "", field: "src_crt_utc_ts" }, { headerName: "", value: "", field: "src_upd_utc_ts" }, { headerName: "", value: "", field: "asgne_cntct_nm" }, { headerName: "", value: "", field: "suprt_wkgp_nm" }];
    this.SummaryLeft = [{ headerName: "", value: "", field: "sht_dn_tx" }, { headerName: "", value: "", field: "asset_tag_nm" }, { headerName: "", value: "", field: "cmnt_tx" }, { headerName: "", value: "", field: "dept_nm" }, { headerName: "", value: "", field: "u_suprt_nm" }, { headerName: "", value: "", field: "mntr_fg" }, { headerName: "", value: "", field: "suprt_usr_wkgp_nm" }];
    this.SummaryOrg = [{ headerName: "", value: "", field: "it_owner_org" }, { headerName: "", value: "", field: "it_support_org" }, { headerName: "", value: "", field: "bus_owner_org" }];
    
    this.SummaryContact = [{ headerName: "", value: "", field: "owned_by_cntct_nm" },{headerName:"", value: "",field:"owned_by_email"}, { headerName: "", value: "", field: "suprt_by_cntct_nm" },{headerName:"", value: "",field:"sup_by_email"}, { headerName: "", value: "", field: "mng_by_cntct_nm" }, { headerName: "", value: "", field: "upd_by_cntct_nm" }, { headerName: "", value: "", field: "crt_by_cntct_nm" },]
    this.CiDetails = [];
    this.CiUpstream = [];
    this.CiDownstream = [];
    this.CiOrgs = [];
    this.showDetail = false;
    this.CIName = { field: "ci_nm", value: " " };
  }
  async getCIDetailByCIKey() {
    let progressbarRef = this.progressbarService.start("Loading .....");
    try {
      let result
      let resultArray = this.reqHelper.createItemArray(this.displayColumns)
      let ciFilter = { and: [{ lgcl_del_fg: { eq: false } }, { ci_ky: { eq: this._ciKey } }] }
      try {
        result = await this.api.ListPgCIs(resultArray["statement"], 1, 0, resultArray["fieldNames"], ciFilter)
        this.CiDetails = result;
        console.log("CI Detail Result")
        console.log(this.CiDetails)
      } catch (e) {
        this.CiDetails = [];
        console.log(e)
      }

      try {
        console.log("CI Orgnisation Result ****")
        let resultArray = this.reqHelper.createItemArray(this.orgDisplayColumns)
        result = await this.api.ListPgCiOrgs(resultArray["statement"], 1, 0, resultArray["fieldNames"], ciFilter)
        this.CiOrgs = result;
        console.log("CI Orgnisation Result ****")
        console.log(this.CiOrgs)
      } catch (e) {
        this.CiOrgs = [];
        console.log(e)
      }

      let downstreamFilter = { and: [{ parnt_ci_ky: { eq: this._ciKey } }, { lgcl_del_fg: { eq: false } }] }
      resultArray = this.reqHelper.createItemArray(this.relDisplayColumns)
      try {
        result = await this.api.ListPgCiDownstream(resultArray["statement"], 300, 0, resultArray["fieldNames"], downstreamFilter)
        this.CiDownstream = result;
        console.log(JSON.stringify(this.CiDownstream))

      } catch (e) {
        this.CiDownstream = [];
        console.log(e)
      }

      let upstreamFilter = { and: [{ child_ci_ky: { eq: this._ciKey } }, { lgcl_del_fg: { eq: false } }] }
      try {
        result = await this.api.ListPgCiUpstream(resultArray["statement"], 300, 0, resultArray["fieldNames"], upstreamFilter)
        this.CiUpstream = result;

        console.log(JSON.stringify(this.CiUpstream))
      } catch (e) {
        this.CiUpstream = [];
        console.log(e)
      }

      if (this.CiDetails != null && this.CiDetails[0] != null) {
        console.log("Before Calling fillViewDataArrays inside if")
        this.CIName.value = this.getColumnValue(this.CIName.field);
        this.fillViewDataArrays();
        console.log("After Calling fillViewDataArrays inside if")
      }
      this.showDetail = true;
      this.progressbarService.stop(progressbarRef);
    } catch (e) {
      this.progressbarService.stop(progressbarRef);
    } finally {
      this.progressbarService.stop(progressbarRef);
    }
  }

  exportToExcel() {
    TableUtilsModule.exportTableToExcel("Report");
  }

  fillViewDataArrays() {
    this.TopLeftColumns.forEach(element => {
      element.headerName = this.displayColumns.find(x => x.field == element.field).displayName
      element.value = this.CiDetails[0][element.field];
    });
    console.log("after TopLeftColumns")

    this.TopRightColumns.forEach(element => {
      console.log(element.field)
      element.headerName = this.displayColumns.find(x => x.field == element.field).displayName
      element.value = this.CiDetails[0][element.field];
    });

    console.log("after TopRightColumns")
    this.SummaryLeft.forEach(element => {
      element.headerName = this.displayColumns.find(x => x.field == element.field).displayName
      element.value = this.CiDetails[0][element.field];
    });


    console.log("after SummaryLeft")
    this.SummaryContact.forEach(element => {
      element.headerName = this.displayColumns.find(x => x.field == element.field).displayName
      element.value = this.CiDetails[0][element.field];
    });

    console.log("after SummaryContact")
    this.SummaryOrg.forEach(element => {
      console.log(element.field)
      element.headerName = this.orgDisplayColumns.find(x => x.field == element.field).displayName
      try {
        console.log(this.CiOrgs[0][element.field])
        element.value = this.CiOrgs[0][element.field];
      }
      catch (e) { }
    });

    console.log("after SummaryOrg")
  }

  getColumnValue(fieldName) {
    //console.log(fieldName)
    let value = "";
    try {
      if (this.CiDetails != null && this.CiDetails[0] != null) {
        value = this.CiDetails[0][fieldName]
      }
    } catch (e) {
      value = "Missing From Database"
    }
    return value;
  }

  getRelationColumns(isUpstreamCI) {
    if (isUpstreamCI) {
      return this.relDisplayColumns.filter(item => item.hide == false && item.field != 'child_ci_nm')
    } else {
      return this.relDisplayColumns.filter(item => item.hide == false && item.field != 'parnt_ci_nm')
    }
  }
}