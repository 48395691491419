import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';


export interface Company {
  id: string;
  companyName: string;
}

export interface UserMenu {
  id: string;
  name: string;
}

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent {
  mobileQuery: MediaQueryList;

 // selectedValue = '1';

  companies: Company[] = [
    { id: '1', companyName: 'DXC' },
    { id: '2', companyName: 'HPE' },
    { id: '3', companyName: 'HPI' }
  ];


  usermenus: UserMenu[] = [
    {
      id: "1",
      name: "Update"
    },
    {
      id: "2",
      name: "New"
    },
    {
      id: "3",
      name: "Look"
    },
    {
      id: "4",
      name: "Lock"
    }
  ];

 

  private _mobileQueryListener: () => void;

  breadcrumbConfig: object = {
    bgColor: '#eee',
    fontSize: '18px',
    fontColor: '#0275d8',
    lastLinkColor: '#000',
    symbol: ' / ',
  };

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver, private router: Router, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  searchServer(searchTerm: string) {
    this.router.navigate(['/search', searchTerm]);
  }

}
