import { CiCustomQueryComponent } from './../ci-custom-query.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../../services/auth.guard';

const routes: Routes = [
  {
    path: '',
    data: {
      breadcrumb: 'Admin'
    },
    children: [
      {
        path: '', 
        data: {
          breadcrumb: null
        },
        canActivate: [AuthGuard],component: CiCustomQueryComponent,
      },
      {
        path: 'admin', component: CiCustomQueryComponent, canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Admin'
        },
      },
      {
        path: 'admin/admin1', component: CiCustomQueryComponent, canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Source Registration'
        },
      },
      {
        path: 'admin/admin2', component: CiCustomQueryComponent, canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Admin Screen 2'
        },
      },
      {
        path: 'admin/admin3', component: CiCustomQueryComponent, canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Admin Screen 3'
        },
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CiCustomQueryRoutingModule { }
