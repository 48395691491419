import { LandingPageComponent } from '../landing-page/landing-page.component';
import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './../../services/auth.guard';
const routes:Routes=[
  {
    path: '',
    data: {
      breadcrumb: null
    },
    component: LandingPageComponent, canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LandingPageRoutingModule { }
