import { Location } from '@angular/common';
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { AmplifyAPIServiceService } from './../../services/amplify-apiservice.service';
import { APIRequestHelper } from "./../../helpers/api-request-helper";
import { MatDialog } from '@angular/material/dialog';
import { ShowColumnsDialogComponent } from "../show-columns-dialog/show-columns-dialog.component"
import { Router } from '@angular/router';
import { ProgressbarService } from "./../../services/progressbar.service"
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
/**
 * This inteface defines the search criteria, this  is being used by Ng-Template in order to generate dynamic template for search
 * Like  Column DropDown (filterColumn)- It contains all db columns on which search can be performed
 *       Operator Dropdown (filterOperator) - It contains all possible operators e.g. Is, Is Empty, Contains, Is Not Empty etc.
 *       Value Inbox (filterValue) - User can enter values in this box
 *       FilterContions - AND/ OR  
 */
interface SearchItem {
  id: number;
  filterColumn: string;
  filterOperator: string;
  filterValue: string;
  filterCondition: boolean;
  filterValueTextFlag: boolean;
  filterValueSelectFlag: boolean;
  filterValueRadioFlag: boolean;
  filterSelectOperators: {}[];
  filterSelectOptions: {}[]
}

@Component({
  selector: 'cilist',
  templateUrl: './cilist.component.html',
  styleUrls: ['./cilist.component.scss']
})

export class CiListComponent implements OnInit {
  /**
   * This Array is used by ag-grid to show columns with headers
   */
  gridColumnDefs = [
    { headerName: "Correlation Id", field: "corltn_id", filter: "agTextColumnFilter", sortable: true, sort: "asc" },
    { headerName: "CI Name", field: "ci_nm", sortable: true, sort: "asc", filter: "agTextColumnFilter", cellRenderer: function (param) { return "<a class='gridHyperLink' href='javascript:void(0)'>" + param.value + "</a>" } },
    { headerName: "Description", field: "sht_dn_tx", filter: "agTextColumnFilter", sortable: true, sort: "asc" },
    { headerName: "Install Status", field: "instl_stat_nm", filter: "agTextColumnFilter", sortable: true, sort: "asc" },
    { headerName: "Assigned To", field: "asgne_cntct_nm", filter: "agTextColumnFilter", sortable: true, sort: "asc" },
    { headerName: "CI KEY", field: 'ci_ky', hide: true }
  ];

  defaultSearchItems = [
    { headerName: "CI Name", field: "ci_nm", filterType: "text", class: "column search-card" },
    { headerName: "Install Status", field: "instl_stat_nm", filterType: "multi", class: "column search-card" },
    { headerName: "Class Name", field: "cls_nm", filterType: "multi", class: "column search-card" },
    { headerName: "Environment", field: "u_use_for_nm", filterType: "multi", class: "column search-card" },
    { headerName: "Correlation Id", field: "corltn_id", filterType: "text", class: "column search-card" }
  ]

  availableItems = [
    { headerName: "Asset Tag", field: "asset_tag_nm", filter: "agTextColumnFilter" },
    { headerName: "Business Criticality", field: "bus_crtclty_nm", filter: "agTextColumnFilter" },
    { headerName: "Class", field: "cls_nm", filter: "agTextColumnFilter" },
    { headerName: "Company", field: "co_nm", filter: "agTextColumnFilter" },
    { headerName: "Comments", field: "cmnt_tx", filter: "agTextColumnFilter" },
    { headerName: "Department", field: "dept_nm", filter: "agTextColumnFilter" },
    { headerName: "Environment", field: "u_use_for_nm", filter: "agTextColumnFilter" },
    { headerName: "Location", field: "loc_nm", filter: "agTextColumnFilter" },
    { headerName: "Monitored", field: "mntr_fg", filter: "agTextColumnFilter" },
    { headerName: "Owned By", field: "owned_by_cntct_nm", filter: "agTextColumnFilter" },
    { headerName: "Supported By", field: "suprt_by_cntct_nm", filter: "agTextColumnFilter" },
    { headerName: "Support Group", field: "suprt_wkgp_nm", filter: "agTextColumnFilter" },
    { headerName: "Updated By", field: "upd_by_cntct_nm", filter: "agTextColumnFilter" },
    { headerName: "Service Classification", field: "bus_srvc_clsfc_nm", filter: "agTextColumnFilter" },
    { headerName: "Supported", field: "u_suprt_nm", filter: "agTextColumnFilter" }
    // { headerName: "Operational Tier 1", field: "oprtnl_catg_tier_1_nm", filter: "agTextColumnFilter" },
    // { headerName: "Operational Tier 2", field: "oprtnl_catg_tier_2_nm", filter: "agTextColumnFilter" },
  ]

  private _queryStatement: string;

  operator = [{ id: "eq", value: "Equal To" },
  { id: "ne", value: "Not Equal To" },
  { id: "beginsWith", value: "Begins With" },
  { id: "endsWith", value: "Ends With" },
  { id: "contains", value: "Contains" }];

  /**
   * This array is used to fill select box with coulmn names as filters
   */
  SearchColumns = [
    { id: "Select Field", value: "Select Field" },
    { id: "asgne_cntct_nm", value: "Assigned To" },
    { id: "asset_tag_nm", value: "Asset Tag" },
    { id: "bus_crtclty_nm", value: "Business Criticality" },
    { id: "sht_dn_tx", value: "Description" },
    { id: "dept_nm", value: "Department" },
    { id: "loc_nm", value: "Location" },
    { id: "mntr_fg", value: "Monitored" },
    { id: "owned_by_cntct_nm", value: "Owned By" },
    { id: "suprt_by_cntct_nm", value: "Supported By" },
    { id: "suprt_wkgp_nm", value: "Support Group" },
    { id: "bus_srvc_clsfc_nm", value: "Service Classification" },
    { id: "upd_by_cntct_nm", value: "Updated By" },
    { id: "u_suprt_nm", value: "User Supported" }

    //{ id: "oprtnl_catg_tier_1_nm", value: "Operational Tier 1" },
    //{ id: "oprtnl_catg_tier_2_nm", value: "Operational Tier 2" }
  ];

  allCIs = [];

  dataSource = this.allCIs;
  ciFilters = null;
  displayColumns = null;
  statement = "";
  showGrid = false;
  showMessage = true;
  MessageToShow = "Please provide search criteria above and click Search."
  gridApi = null;
  installStatus = [];
  classNames = [];
  uUSedFor = [];
  bus_crtclty_nm = [];
  bus_srvc_clsfc_nm = [];
  filterStatusValue = "";
  filterCiNameValue = null;
  filterUUsedValue = "";
  filterClassValue = "";
  filterLOVDefaultValue = null;
  filterPortfolioValue = null;
  IsWait = false;
  //gridOptions = <GridOptions>{};

  /**
   * Setting Form variables used by dynamic template to create search items
   */
  public form: {
    searchItems: SearchItem[];
  };



  constructor(private api: AmplifyAPIServiceService, private actRoute: ActivatedRoute, private reqHelper: APIRequestHelper, public dialog: MatDialog, public router: Router, private progressbarService: ProgressbarService, private location: Location, private fb: FormBuilder) {
    this.form = { searchItems: [] }
    this.addSearchItem(undefined);
    this.fillLOVs("instl_stat_nm", "Live", "InstallStatusLOV");
    this.fillLOVs("instl_stat_nm", "-All-", "InstallStatusLOV");
    this.fillLOVs("cls_nm", "-All-", "ClassNameLOV");
    this.fillLOVs("u_use_for_nm", "-All-", "UUSeNameLOV");
    this.fillLOVs("bus_crtclty_nm", "-All-", "BusinessCriticalityNameLov");
    this.fillLOVs("bus_srvc_clsfc_nm", "-All-", "BusinessServiceNameLOV");
  }

  ngOnInit() {
    console.log("This URL Info ", this.router.url);
    if (this.router.url == '/search') {
      let refinedfilter = "search/" + '{"and":[{"instl_stat_nm":{"ne":"Retired"}},{"lgcl_del_fg":{"eq":false}}]}';
      console.log("Refined Filter ", JSON.stringify(refinedfilter))
      this.router.navigate([refinedfilter], { queryParams: {}, replaceUrl: true });
      if (this.router.url == '/search') {
        console.log("This URL Info After Search", this.router.url);
      }
    }
    else if (this.router.url == '/search/applist') {
      this.applist();
    }
    else if (!this.router.url.includes('applist')) {
      console.log("Inside Paramter")
      this.actRoute.params.subscribe(params => {
        this._queryStatement = params.query;
        let sqlstatement = "";
        if (!isNullOrUndefined(this._queryStatement)) {
          console.log("Paramter ", sqlstatement.replace(" ", ""));
          sqlstatement = this._queryStatement.replace("'", "").replace("'", "");
          console.log(sqlstatement);
          if (sqlstatement.includes('applist')) {
            this.applist();
          }
          else {
            this.ciFilters = JSON.parse(sqlstatement);
            this.getAllCIsOnStartup();
          }
          this.ciFilters = JSON.parse(sqlstatement);
          this.getAllCIsOnStartup();
        }
      });
    }
  }

  /**
   * Method that will open dialog and will show screen to select list of available columns that user can choose to display in ag-grid
   */
  openDialog(): void {
    const dialogRef = this.dialog.open(ShowColumnsDialogComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        AvailableColumns: this.availableItems, sortable: true, sort: "asc",
        SelectedColumns: this.gridColumnDefs
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.gridColumnDefs = result;
        //Add CIKEY as hidden column , this will be used in getting CI Detail
        this.gridColumnDefs.push({ headerName: "CI KEY", field: 'ci_ky', hide: true })
        if (this.gridApi != null) {
          this.gridApi.setColumnDefs(this.gridColumnDefs)
        }
        this.changeDataSource()
      }
    });
  }

  public selectElements() {

    this.form.searchItems.splice(0, 1,
      {
        id: Date.now(),
        filterColumn: "bus_srvc_clsfc_nm",
        filterOperator: "eq",
        filterValue: "Application Service",
        filterCondition: true,
        filterValueTextFlag: true,
        filterValueSelectFlag: false,
        filterValueRadioFlag: false,
        filterSelectOperators: [{ id: "eq", value: "Equal To" }],
        filterSelectOptions: []
      }
    )

  }
  public applist() {
    let AppdefaultFilter = []
    AppdefaultFilter.push({ "instl_stat_nm": { "ne": "Retired" } });
    AppdefaultFilter.push({ "lgcl_del_fg": { "eq": false } });
    AppdefaultFilter.push({ "bus_srvc_clsfc_nm": { "eq": "Application Service" } });
    let arrayFields = this.reqHelper.createItemArray(this.gridColumnDefs);
    this.displayColumns = arrayFields["fieldNames"];
    this.statement = arrayFields["statement"];
    this.ciFilters = this.reqHelper.createFilter(this.form.searchItems, AppdefaultFilter);
    this.selectElements();
    let message = "Please provide search criteria above and click Search."
    this.hideGridWithDisplayMessage(message);

  }

  public delay(ms: number) {
    return new Promise(resolve => setTimeout(() => resolve(), ms)).then(() => console.log("fired"));
  }

  /**
  * This method is called on click event of AND | OR button, and it adds template dynamically with some fomvalues
  * @param flag TRUE|FALSE
  *         Its value is true if user clicks AND button
  *         Otherwise FALSE
  * @param index - template index
  */
  public addSearchItem(flag): void {
    /**
     * Push item in SelectItem array with default values in order to generate dynamic template.
     */
    this.form.searchItems.push(
      {
        id: Date.now(),
        filterColumn: "Select Field",
        filterOperator: "Select Operator",
        filterValue: "",
        filterCondition: false,
        filterValueTextFlag: false,
        filterValueSelectFlag: false,
        filterValueRadioFlag: false,
        filterSelectOperators: [],
        filterSelectOptions: []
      }
    )
  }

  /**
   * This method is called on click event of Clear button , to delete selected template
   * @param index - Represents index or row number of template to be deleted from search screen
   */
  public removeSearchItem(index: number): void {
    if (index != undefined && index > 0) {
      this.form.searchItems.splice(index, 1);
    } else if (index == 0) {
      this.form.searchItems = [];
      this.addSearchItem(undefined);
    }
  }

  /**
   * This method is called on click event Search button
   * It will evaluate form values and create json array in format required for AWS API 
   * Then it calls API method to get values from Database and
   * Then bind ag-grid with values returned from database.
   */
  async changeDataSource() {
    /**
     * If Form values array searchItem[] is blank then call API without any filter criteria or get all the records from db
     * Otherwise loop over each item of the array searchItem[] , and 
     */
    let arrayFields = this.reqHelper.createItemArray(this.gridColumnDefs);
    this.displayColumns = arrayFields["fieldNames"];
    this.statement = arrayFields["statement"];

    let defaultFilter = [];
    if (this.filterStatusValue == "Live") {
      defaultFilter.push({ "instl_stat_nm": { ne: "Retired" } })
    } else if (this.filterStatusValue!="-All-" && this.filterStatusValue!="Live") {
      defaultFilter.push({ "instl_stat_nm": { eq: "this.filterStatusValue" } })
    }

    if (this.filterCiNameValue != null && this.filterCiNameValue != "") {
      defaultFilter.push({ "ci_nm": { contains: this.filterCiNameValue } })
    }

    if (this.filterPortfolioValue != null && this.filterPortfolioValue != "") {
      defaultFilter.push({ "corltn_id": { contains: this.filterPortfolioValue } })
    }

    if (this.filterClassValue != "-All-") {
      defaultFilter.push({ "cls_nm": { eq: this.filterClassValue } })
    }

    if (this.filterUUsedValue != "-All-") {
      defaultFilter.push({ "u_use_for_nm": { eq: this.filterUUsedValue } })
    }
    defaultFilter.push({ "lgcl_del_fg": { eq: false } })
    let SearchError: Boolean = false;
    if (this.form.searchItems != undefined) {
      console.log("Free Text Items");
      for (let items in this.form.searchItems) {
        if (this.form.searchItems[items].filterValue.length == 0 && this.form.searchItems[items].filterCondition == true)
          SearchError = true;
      }
      if (SearchError)
        this.hideGridWithDisplayMessage("Enter a Valid Search Crietria");
      else {
        this.ciFilters = this.reqHelper.createFilter(this.form.searchItems, defaultFilter);
        let refinedfilter = this.router.url + "/" + JSON.stringify(this.ciFilters);
        // this.router.navigate([refinedfilter], { queryParams: {}, replaceUrl: true });
        this.getAllCIs();
      }


    }

    //console.log("inside change datasource")
    //console.log(this.gridColumnDefs);

  }
  async getAllCIsOnStartup() {
    let arrayFields = this.reqHelper.createItemArray(this.gridColumnDefs);
    this.displayColumns = arrayFields["fieldNames"];
    this.statement = arrayFields["statement"];
    //this.getAllCIs();

  }
  /**
   * This method will call API and get all recodrs from database
   */
  async getAllCIs() {
    let response;
    this.IsWait = true
    this.showGrid = false;
    this.showMessage = false;
    response = null;
    try {
      response = await this.api.ListPgCIs(this.statement, 10000, 0, this.displayColumns, this.ciFilters)
    } catch (error) {
      console.log(error)
      response = null;
    }
    this.allCIs = response;
    if (response != null) {
      this.dataSource = this.allCIs;
      this.showGrid = true;
      this.showMessage = false;
    } else {
      let message = "No Configuration Items found matching selected criteria. Please use filters above to adjust your search criteria."
      this.hideGridWithDisplayMessage(message)
    }
    this.IsWait = false
  }

  hideGridWithDisplayMessage(msg: string) {
    this.dataSource = null;
    this.allCIs = null;
    this.showGrid = false;
    this.showMessage = true;
    this.MessageToShow = msg;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }


  onCellClicked(event: any) {
    if (event.column.colId == "ci_nm") {
      this.router.navigate(["/ci/detail/" + event.node.data.ci_ky])
      // execute the action as you want here in on click of hyperlink
    }
  }

  resetFilter() {
    this.gridApi.setFilterMode(null);
  }

  async hideShowValueControls(searchItem) {
    searchItem.filterCondition = true;
    searchItem.filterValue = ""
    console.log("inside hideShowValueControls")
    console.log("***" + JSON.stringify(searchItem))
    switch (searchItem.filterColumn) {
      case "mntr_fg": searchItem.filterValueSelectFlag = true;
        searchItem.filterSelectOperators.push({ id: "eq", value: "Equal To" })
        searchItem.filterOperator = "eq";
        searchItem.filterValueTextFlag = false;
        searchItem.filterValue = false
        searchItem.filterSelectOptions = [];
        searchItem.filterSelectOptions.push({ id: true, value: "Yes" })
        searchItem.filterSelectOptions.push({ id: false, value: "No" })
        break;
      case "bus_crtclty_nm": searchItem.filterValueSelectFlag = true;
        searchItem.filterValueTextFlag = false;
        searchItem.filterValueSelectFlag = true;
        searchItem.filterSelectOptions = [];
        this.operator.forEach(element => searchItem.filterSelectOperators.push({ id: element.id, value: element.value }))
        searchItem.filterOperator = "eq";
        this.bus_crtclty_nm.forEach(item => searchItem.filterSelectOptions.push({ id: item.value, value: item.value }));
        break;
      case "bus_srvc_clsfc_nm": searchItem.filterValueSelectFlag = true;
        searchItem.filterValueTextFlag = false;
        searchItem.filterValueSelectFlag = true;
        searchItem.filterSelectOptions = [];
        this.operator.forEach(element => searchItem.filterSelectOperators.push({ id: element.id, value: element.value }))
        searchItem.filterOperator = "eq";
        this.bus_srvc_clsfc_nm.forEach(item => searchItem.filterSelectOptions.push({ id: item.value, value: item.value }));
        break;
      default:
        searchItem.filterValueTextFlag = true;
        searchItem.filterValueSelectFlag = false;
        this.operator.forEach(element => searchItem.filterSelectOperators.push({ id: element.value, value: element.value }))
        searchItem.filterOperator = "contains";

        break;
    }
  }

  async fillLOVs(lovName, defaultValue, cacheName) {
    let progressbarRef = this.progressbarService.start("Loading .....");
    let arrLOV = null
    if (localStorage.getItem(cacheName) != null) {
      arrLOV = JSON.parse(localStorage.getItem(cacheName))
    }
    else {
      arrLOV = await this.api.LovPgCIs(lovName, [lovName])
      localStorage.setItem(cacheName, JSON.stringify(arrLOV));
    }

    switch (lovName) {
      case "instl_stat_nm":
        this.installStatus.push({ display: defaultValue, value: defaultValue })
        arrLOV.forEach(element => {
          this.installStatus.push({ display: element[lovName], value: element[lovName] })
          // this.installStatus.push({ display: "All",value:"All"})
        });

        this.filterStatusValue = defaultValue;
        //console.log(this.installStatus)
        break;
      case "cls_nm":
        this.classNames.push({ display: defaultValue, value: defaultValue })
        arrLOV.forEach(element => {
          this.classNames.push({ display: element[lovName], value: element[lovName] })
        });
        this.filterClassValue = defaultValue; break;
      case "u_use_for_nm":
        this.uUSedFor.push({ display: defaultValue, value: defaultValue })
        arrLOV.forEach(element => {
          this.uUSedFor.push({ display: element[lovName], value: element[lovName] })
        });
        this.filterUUsedValue = defaultValue; break;
      case "bus_crtclty_nm":
        this.bus_crtclty_nm.push({ display: defaultValue, value: defaultValue })
        arrLOV.forEach(element => {
          this.bus_crtclty_nm.push({ display: element[lovName], value: element[lovName] })
        });
        this.filterUUsedValue = defaultValue; break;
      case "bus_srvc_clsfc_nm":
        this.bus_srvc_clsfc_nm.push({ display: defaultValue, value: defaultValue })
        arrLOV.forEach(element => {
          this.bus_srvc_clsfc_nm.push({ display: element[lovName], value: element[lovName] })
        });
        this.filterUUsedValue = defaultValue; break;
    }
    console.info(JSON.stringify(this.bus_crtclty_nm));
    this.progressbarService.stop(progressbarRef);
  }

  resetSearch() {
    this.form.searchItems = [];
    this.addSearchItem(undefined);
    this.filterUUsedValue = "-All-";
    this.filterClassValue = "-All-";
    this.filterStatusValue = "Live";
    this.filterCiNameValue = "";

    let message = "Please provide search criteria above and click Search."
    this.hideGridWithDisplayMessage(message);
    let url = "/search";
    this.router.navigate([url], { queryParams: {}, replaceUrl: true });
  }

  getSelectedOptions(selected) {
    this.filterUUsedValue = selected;
  }

  getSelectedInstallStatus(selected) {
    this.filterStatusValue = selected;
  }

  getSelectedClassName(selected) {
    this.filterClassValue = selected;
  }



}
