import { Injectable } from "@angular/core";
/**
 * This is is helper class , and will be responsible to take user inputs and format it required by API methods.
 *  */
@Injectable({
    providedIn: "root"
})
export class APIRequestHelper {
    /**
    * This method in future will take inputs from user, it will accept list of columns that needs to be displayed on UI.
    * It will convert the list to below mentioned format.
    * NOTE :This method in under proceess, created to support POC, need to be updated in future 
    */
    createItemArray(selectedColumnsList) {

        let statement = "";
        let arrayField = [];
        let fieldName = ""
        const resultArray = [];
        for (let index in selectedColumnsList) {

            fieldName = selectedColumnsList[index]["field"];
            if (!fieldName.startsWith("NO_")) {
                statement = statement + " " + fieldName;
                arrayField.push(fieldName);
            }
        }

        //arrayField.push("ci-")
        console.log("Statemeny value")
        console.log(statement)
        console.log("Fields Array value")
        console.log(JSON.stringify(arrayField))
        resultArray["statement"] = statement;
        resultArray["fieldNames"] = arrayField;
        return resultArray;
    }

    /**
     * This method will create filters, in format required by API.
     * e.g or: [{ asgnecntcnm: { eq: "AsgneCntcNm # 86" } }, { asgnecntcnm: { eq: "AsgneCntcNm # 60" } }] }
     * @param filterItems - Array contains filter column , value and conditions (and / or / gt / lt etc)
     * NOTE :This method in under proceess, created to support POC, need to be updated in future 
     */
    createFilter(filterItems, defaultFilter) {
        let operatorObj: {};
        let columnObj: {};
        let finalFilterValue = [];
        let resultArray
        let ciFilters = null;
        for (let obj in filterItems) {
            //If search items are selectted then list all ci items from db and do not set any Filter
            if (filterItems[obj].filterOperator != "Select Operator" && filterItems[obj].filterColumn != "Select Field") {
                //set operator object like {eq:"value"}
                operatorObj = { [filterItems[obj].filterOperator]: filterItems[obj].filterValue }
                //console.log(operatorObj);

                //set column object like { ci_nm: {eq:"value"}}
                columnObj = { [filterItems[obj].filterColumn]: operatorObj }
                //console.log(columnObj);

                //Check if it is first search item ? If yes then set variable finalFilterValue with columnObj
                //else check FilterCondition AND\OR and append serach item
                //if (obj != "0") {
                //Note : Commented below code as nested clause is not supported by backend now.
                // if (filterItems[obj].filterCondition) {
                //     finalFilterValue = { and: [columnObj, finalFilterValue] }
                // } else
                //  {
                //     finalFilterValue = { or: [columnObj, finalFilterValue] }
                // }

                //} else {
                // finalFilterValue = columnObj;
                //Note : Commented above code as nested clause is not supported by backend now.

                // }
                finalFilterValue.push(columnObj);
            }
        }
        defaultFilter.forEach(element => {
            finalFilterValue.push(element)
        });
        //finalFilterValue.push(defaultFilter);

        resultArray = { and: finalFilterValue }
        ciFilters = finalFilterValue;
        console.log("Filter Applied **")
        console.log(JSON.stringify(resultArray))
        return resultArray;
    }
}