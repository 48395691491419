import { Component, OnInit, NgZone } from "@angular/core";
import { AuthService } from "../auth.service";
import { Router } from "@angular/router";
import { Hub } from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";

@Component({
  selector: "app-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.scss"],
})
export class AuthComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private _router: Router,
    private _zone: NgZone
  ) {}

  ngOnInit() {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          //console.log("auth component : hub listen")
          this.authService.loggedIn = true;
          localStorage.setItem("isloggedIn", "true");
          this._zone.run(() => {
            this._router.navigate(["/home"]);
          });
          break;
        case "signOut":
          this._zone.run(() => {
            //this._router.navigate(['/login']);
          });
          break;
      }
    });
  }

  async signIn() {
    let res = await this.authService.signIn("GlobalPass");
    console.log("Logging In  " + res);
    return res;
  }
}
