/* tslint:disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation } from "@aws-amplify/api";

export type LovPgCIsQuery = {
  __typename: "lovCI";
  cls_nm: string | null;
  instl_stat_nm: string | null;
  u_use_for_nm: string | null;
};

export type ModelCIStringInput = {
  eq?: string | null;
  ne?: string | null;
  beginsWith?: string | null;
  endsWith?: string | null;
  contains?: string | null;
};

export type ModelCIBooleanInput = {
  eq?: boolean | null;
};

export type ModelCIIntInput = {
  eq?: number | null;
  ne?: number | null;
};

export type ModelCIFilterInput = {
  asset_tag_nm?: ModelCIStringInput | null;
  asgne_cntct_nm?: ModelCIStringInput | null;
  asset_sk?: ModelCIStringInput | null;
  can_prt_fg?: ModelCIBooleanInput | null;
  ci_nm?: ModelCIStringInput | null;
  cls_nm?: ModelCIStringInput | null;
  co_ky?: ModelCIStringInput | null;
  fault_ct?: ModelCIIntInput | null;
  instl_stat_nm?: ModelCIStringInput | null;
  u_use_for_nm?: ModelCIStringInput | null;
  loc_nm?: ModelCIStringInput | null;
  oprtnl_catg_tier_1_nm?: ModelCIStringInput | null;
  oprtnl_catg_tier_2_nm?: ModelCIStringInput | null;
  owned_by_cntct_nm?: ModelCIStringInput | null;
  suprt_by_cntct_nm?: ModelCIStringInput | null;
  suprt_wkgp_nm?: ModelCIStringInput | null;
  lgcl_del_fg?: ModelCIBooleanInput | null;
  mntr_fg?: ModelCIBooleanInput | null;
  ci_ky?: ModelCIStringInput | null;
  corltn_id?: ModelCIStringInput | null;
  and?: Array<ModelCIFilterInput | null> | null;
  or?: Array<ModelCIFilterInput | null> | null;
};

export type ModelCIRelationFilterInput = {
  child_ci_ky?: ModelCIStringInput | null;
  ci_ky?: ModelCIStringInput | null;
  parnt_ci_ky?: ModelCIStringInput | null;
  lgcl_del_fg?: ModelCIBooleanInput | null;
  instl_stat_nm?: ModelCIStringInput | null;
  ci_nm?: ModelCIStringInput | null;
  co_nm?: ModelCIStringInput | null;
  co_ky?: ModelCIStringInput | null;
  and?: Array<ModelCIRelationFilterInput | null> | null;
  or?: Array<ModelCIRelationFilterInput | null> | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};
export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};
export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelanaIntelliSearchContentFilterInput = {
  key?: ModelIDInput | null;
  value?: ModelStringInput | null;
  and?: Array<ModelanaIntelliSearchContentFilterInput | null> | null;
  or?: Array<ModelanaIntelliSearchContentFilterInput | null> | null;
  not?: ModelanaIntelliSearchContentFilterInput | null;
};

@Injectable({
  providedIn: "root"
})

export class AmplifyAPIServiceService {
  async ListPgCIs(
    items?: string,
    limit?: number,
    offset?: number,
    fields?: Array<string | null>,
    filter?: ModelCIFilterInput,
    newfilter?: ModelCIFilterInput
  ) {
    const statement = "query ListPgCIs($limit: Int, $offset: Int, $fields: [String], $filter: ModelCIFilterInput, $newfilter: ModelCIFilterInput) {" +
      "listPgCIs(limit: $limit, offset: $offset, fields: $fields, filter: $filter, newfilter: $newfilter) {" +
      "__typename"
      + items +
      " } }";
    const gqlAPIServiceArguments: any = {};
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (offset) {
      gqlAPIServiceArguments.offset = offset;
    }
    if (fields) {
      gqlAPIServiceArguments.fields = fields;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (newfilter) {
      gqlAPIServiceArguments.newfilter = newfilter;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return response.data.listPgCIs;
  }

  async LovPgCIs(items?: string, fields?: Array<string | null>) {
    const statement = "query LovPgCIs($fields: [String]) {" +
      "lovPgCIs(fields: $fields) {" +
      "__typename " +
      items +
      "}}";
    const gqlAPIServiceArguments: any = {};
    if (fields) {
      gqlAPIServiceArguments.fields = fields;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return response.data.lovPgCIs;
  }

  async ListPgCiUpstream(
    items?: string,
    limit?: number,
    offset?: number,
    fields?: Array<string | null>,
    filter?: ModelCIRelationFilterInput
  ) {
    const statement = "query ListPgCiUpstream($limit: Int, $offset: Int, $fields: [String], $filter: ModelCIRelationFilterInput) {" +
      "listPgCIUpstream(limit: $limit, offset: $offset, fields: $fields, filter: $filter) {" +
      "__typename " +
      items +
      "}}";
    const gqlAPIServiceArguments: any = {};
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (offset) {
      gqlAPIServiceArguments.offset = offset;
    }
    if (fields) {
      gqlAPIServiceArguments.fields = fields;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return response.data.listPgCIUpstream;
  }

  async ListPgCiDetails(
    items?: string,
    limit?: number,
    offset?: number,
    fields?: Array<string | null>,
    filter?: ModelCIRelationFilterInput
  ) {
    const statement = "query ListPgCiDetails($limit: Int, $offset: Int, $fields: [String], $filter: ModelCIRelationFilterInput) {" +
      "listPgCIDetails(limit: $limit, offset: $offset, fields: $fields, filter: $filter) {" +
      "__typename " + items + " } }";
    const gqlAPIServiceArguments: any = {};
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (offset) {
      gqlAPIServiceArguments.offset = offset;
    }
    if (fields) {
      gqlAPIServiceArguments.fields = fields;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return response.data.listPgCIDetails;
  }

  async ListPgCiDownstream(
    items?: string,
    limit?: number,
    offset?: number,
    fields?: Array<string | null>,
    filter?: ModelCIRelationFilterInput
  ) {
    const statement = "query ListPgCiDownstream($limit: Int, $offset: Int, $fields: [String], $filter: ModelCIRelationFilterInput) {" +
      "listPgCIDownstream(limit: $limit, offset: $offset, fields: $fields, filter: $filter) {" +
      "__typename " + items +
      "}}";
    const gqlAPIServiceArguments: any = {};
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (offset) {
      gqlAPIServiceArguments.offset = offset;
    }
    if (fields) {
      gqlAPIServiceArguments.fields = fields;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return response.data.listPgCIDownstream;
  }
  async ListPgCiOrgs(
    items?: string,
    limit?: number,
    offset?: number,
    fields?: Array<string | null>,
    filter?: ModelCIRelationFilterInput
  ) {
    const statement = "query ListPgCiOrgs($limit: Int, $offset: Int, $fields: [String], $filter: ModelCIRelationFilterInput) {" +
      "listPgCIOrgs(limit: $limit, offset: $offset, fields: $fields, filter: $filter) {" +
      "__typename " + items +
      "}}";
    const gqlAPIServiceArguments: any = {};
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (offset) {
      gqlAPIServiceArguments.offset = offset;
    }
    if (fields) {
      gqlAPIServiceArguments.fields = fields;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return response.data.listPgCIOrgs;
  }

  async ListAnaIntelliSearchContents(
    filter?: ModelanaIntelliSearchContentFilterInput,
    limit?: number,
    nextToken?: string
  ) {
    const statement = `query ListAnaIntelliSearchContents($filter: ModelanaIntelliSearchContentFilterInput, $limit: Int, $nextToken: String) {
        listAnaIntelliSearchContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            key
            value
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return response.data.listAnaIntelliSearchContents;
  }
}