import { MediaMatcher } from '@angular/cdk/layout';
import { Router, ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { UserMenu } from './../../services/servers.service';
import { AuthService } from "./../../auth.service"
import { last } from 'rxjs/operators';
import { AmplifyAPIServiceService } from "./../../services/amplify-apiservice.service"
import {ProgressbarService} from "./../../services/progressbar.service"
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent {
  mobileQuery: MediaQueryList;

  signedIn: boolean;

  usermenus: UserMenu[] = [];
  homeMenu: UserMenu[] = [];
  Matching:boolean=true;

  private _mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, public router: Router, public authService: AuthService, public actRouter: ActivatedRoute,private api: AmplifyAPIServiceService,private progress: ProgressbarService) {

    this.mobileQuery = media.matchMedia('(max-width: 400px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  // ngOnInit() {
    
  //   this.loadHomeMenu();
  // }
  async ngOnInit(): Promise<any> {
    let progress = this.progress.start();
    Promise.all([
      this.isDXCUser()
    ]).then(result => {
      this.Matching = result[0];
    })
    this.progress.stop(progress);
  }

  async isDXCUser(): Promise<boolean> {
    let info = await this.api.ListAnaIntelliSearchContents({ key: { eq: "CompanyName" } })
    try {
      if (info != null && info.items != null && info.items[0] != null) {
        if (info.items[0].value.toUpperCase() === this.authService.getAltCompanyCode().toUpperCase()) {
          this.loadHomeMenu();
          return true
        }
        else {
          this.router.navigate(['NoAuth'], { queryParams: {}, replaceUrl: true });
          return false
        }
      }
    } catch (e) {
      return false

    }
  }

  getCurrentAuthenticatedUserMail() {
    return this.authService.getUserMail();
  }

  getCompanyName() {
    return this.authService.getAltCompanyCode();
  }

  getCurrentAuthenticatedUserName() {
    let firstName = this.authService.getUserName()
    let lastName = this.authService.getUserLastName()
    // console.log(firstName + " " + lastName)
    return  (firstName) != undefined ? firstName : "" + " " +  (lastName) != undefined ? lastName : ""
  }

  getCurrentAuthenticatedUserNameInitials() {
    let firstName = this.authService.getUserName()
    let lastName = this.authService.getUserLastName()
    let firstLetter = ""
    let secondLetter = ""
    if (firstName != null)
      firstLetter = firstName.charAt(0)

    if (lastName != null)
      secondLetter = lastName.charAt(0)
    //console.log(firstLetter + secondLetter)
    return firstLetter + secondLetter
  }

  loadSearchMenu() {
    this.homeMenu = [];
    this.usermenus = [{ id: "1", name: "Configurations", routerLinkActive: "", ngCssClassNames: "disableHeaderPointer" },
    { id: "2", name: "Configuration Items", routerLink: "search", routerLinkActive: "activeListItem", ngCssClassNames: "submenuItem rightMenuHover" },
    { id: "3", name: "Applications", routerLink: "search/applist", routerLinkActive: "activeListItem", ngCssClassNames: "submenuItem rightMenuHover" }
    /* { id: "3", name: "Server", routerLink: "search/serverlist", routerLinkActive: "activeListItem", ngCssClassNames: "submenuItem rightMenuHover" } */];
  }

  /*   loadAdminMenu() {
      this.homeMenu = [];
      this.usermenus = [{ id: "1", name: "Administrator", routerLinkActive: "", ngCssClassNames: "disableHeaderPointer" },
      { id: "2", name: "Source Registration", routerLink: "admin", routerLinkActive: "activeListItem", ngCssClassNames: "submenuItem rightMenuHover" },
      { id: "3", name: "Admin Menu 2", routerLink: "admin/admin2", routerLinkActive: "activeListItem", ngCssClassNames: "submenuItem rightMenuHover" },
      { id: "3", name: "Admin Menu 3", routerLink: "admin/admin3", routerLinkActive: "activeListItem", ngCssClassNames: "submenuItem rightMenuHover" }];
    } */

  loadHomeMenu() {
    this.usermenus = [];
    this.homeMenu = [{ id: "1", name: "Home", routerLink: "home", routerLinkActive: "activeListItem", ngCssClassNames: "rightMenuHover" },
    { id: "2", name: "Search", routerLink: "search", routerLinkActive: "activeListItem", ngCssClassNames: "rightMenuHover" }/* ,
    { id: "3", name: "Administrator", routerLink: "admin", routerLinkActive: "activeListItem", ngCssClassNames: "rightMenuHover" } */];
  }

  getRightMenus(menuName) {
    // console.log("menuName " + menuName)
    if (menuName == "Home") {
      this.loadHomeMenu()
    }
    if (menuName == "Search") {
      this.loadSearchMenu()
    }
    /*  if (menuName == "Administrator") {
       this.loadAdminMenu();
     } */
  }
}


