import { AuthGuard } from './../../services/auth.guard';
import { NgModule } from '@angular/core';
import { CiDetailsComponent } from '../ci/ci-details/ci-details.component';
import { CiListComponent } from '../ci/cilist.component';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageModule } from '../landing-page/landing-page.module';

const routes: Routes = [
  {
    path: '',
    data: {
      breadcrumb: "Search"
    },
    children: [
      {
        path: '', redirectTo: '/home', pathMatch: 'full',

        data: {
          breadcrumb: null, component: LandingPageModule,
        },
      },
      {
        path: 'search/:query', component: CiListComponent, canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Search', component: CiListComponent, canActivate: [AuthGuard],
        },
      },

      {
        path: 'ci/detail/:id', component: CiDetailsComponent, canActivate: [AuthGuard],
        data: {
          breadcrumb: 'CI Details', component: CiListComponent, canActivate: [AuthGuard],
        },
      },
      {
        path: 'search', component: CiListComponent, canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Search',
        },
      },
      {
        path: 'ci/detail/:id', component: CiDetailsComponent, canActivate: [AuthGuard],
        data: {
          breadcrumb: 'CI Details', component: CiListComponent, canActivate: [AuthGuard],
        },
      },
      {
        path: 'search/cilist', component: CiListComponent, canActivate: [AuthGuard],
        data: {
          breadcrumb: 'CI List'
        },
      },
      {
        path: 'search/applist', component:CiListComponent, canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Application List'
        },
      },
      {
        path: 'search/serverlist', component: CiListComponent, canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Server List'
        },
      },
    ],
  }

]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CiRoutingModule { }
