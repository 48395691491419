import { Component, OnInit } from '@angular/core';
import { AmplifyAPIServiceService } from './../services/amplify-apiservice.service';
import { APIService } from './../API.service'
@Component({
  selector: 'app-configitems',
  templateUrl: './configitems.component.html',
  styleUrls: ['./configitems.component.scss']
})
export class ConfigitemsComponent implements OnInit {

  allConfigs: any = [];

  constructor(private api: APIService) { }

  async ngOnInit() {
    this.getAllConfigItems();
  }

  async getAllConfigItems() {

    let statement = "  ci_nm instl_stat_nm cls_nm co_nm asset_tag_nm loc_nm asgne_cntct_nm owned_by_cntct_nm suprt_wkgp_nm "
    const response = this.api.ListPgCIs(100, 0,
      ["ci_nm", "instl_stat_nm", "cls_nm", "co_nm", "asset_tag_nm", "loc_nm", "asgne_cntct_nm", "owned_by_cntct_nm", "suprt_wkgp_nm"],
      { "and": [{ "instl_stat_nm": { "ne": "Retired" } }, { "cls_nm": { "eq": "Business Service" } }] }
    );
    //console.log(response);

    this.allConfigs = response;
  }
}
